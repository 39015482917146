import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import MainRouter from "pages/index";
import { ManagedUIContext } from "contexts/ui.context";
import "index.css";
import "flatpickr/dist/themes/light.css";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "components/error/ErrorBoundary";
import ManagedModal from "components/common/modal/managed-modal";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

const contextClass: any = {
  success: "bg-green-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-blue-400",
  dark: "bg-white-600 font-gray-300",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ManagedUIContext>
        <ToastContainer
          toastClassName={({ type }: any) =>
            contextClass[type || "default"] +
            " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
          }
          bodyClassName={() => "flex text-sm font-white font-med block p-3"}
          autoClose={3000}
        />
        <ErrorBoundary>
          <RouterProvider router={MainRouter} />
        </ErrorBoundary>
        <ManagedModal />
      </ManagedUIContext>
    </QueryClientProvider>
  </React.StrictMode>
);
