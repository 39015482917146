import React from "react";

type Props = {};

export default function SocialMediaGroup({}: Props) {
  return (
    <div className="w-full h-full">
        <p className="text-base text-secondryText font-normal pb-2">Using social networking accounts
</p>
      <div className="h-10 w-full flex items-center rounded-lg outline outline-google outline-offset-0 px-4 py-2 cursor-pointer my-4">
        <img src="/assets/images/google.png" alt="google" className="w-5 h-5" />
        <p className="text-sm text-google font-medium px-3">
          Connect with Google
        </p>
      </div>
      <div className="h-10 w-full flex items-center rounded-lg outline outline-facebook outline-offset-0 px-4 py-2 cursor-pointer my-4">
        <img
          src="/assets/images/facebook.png"
          alt="facebool"
          className="w-5 h-5"
        />
        <p className="text-sm text-facebook font-medium px-3">
          Connect with Facebook
        </p>
      </div>
      <div className="h-10 w-full flex items-center rounded-lg outline outline-black outline-offset-0 px-4 py-2 cursor-pointer my-4">
        <img src="/assets/images/apple.png" alt="apple" className="w-5 h-5" />
        <p className="text-sm font-medium px-3">Sign in with Apple</p>
      </div>
    </div>
  );
}
