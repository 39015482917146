import Modal from "components/common/modal/modal";
import Button from "components/ui/buttton";
import Image from "components/ui/Image";
import InputCard from "components/ui/InputCard";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { Formik, Form, Field } from "formik";
import { useIconsQuery } from "framework/basic-rest/icons/get-icons";
import { useAddWalletMutation } from "framework/basic-rest/wallet/add-wallet";
import { useWalletsQuery } from "framework/basic-rest/wallet/get-wallets";
import { useCallback, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { WalletValidation } from "validation-schema/wallet-yup-schema";
import CurrencyInputCard from "./CurrencyInputCard";
import CurrencySelectionModal from "./CurrencySelectionModal";
import IconSelectModal from "./IconSelectModal";

type Props = {};

export default function AddWallet({}: Props) {
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);
  const [showIconModal, setShowIconModal] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<any>();
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const { closeModal } = useUI();
  const { icons } = useApplication();
  const { refetch } = useWalletsQuery();

  const { mutateAsync } = useAddWalletMutation();

  const onSelectedIcon = useCallback(
    (values: any) => {
      setSelectedIcon(values);
    },
    [selectedIcon]
  );
  const onSelectedCurrency = useCallback(
    (values: any) => {
      setSelectedCurrency(values);
    },
    [selectedCurrency]
  );

  const onsubmit = async (values: any, setErrors: any) => {
    const final_value = {
      currency_code: values.currency.code,
      name: values.name,
      type: 1,
      icon_name: values.icon.icon_name,
    };

    const id = toast.loading("Please Wait");

    console.log(values);

    try {
      await mutateAsync(final_value);

      toast.update(id, {
        render: "Wallet Added SuccessFully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      refetch();
      closeModal();
    } catch (error: any) {
      if (error?.response) {
        if (error?.response.status == 422) {
          setErrors(error.response.data.data);
          toast.update(id, {
            render: "something went wrong! please try again",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }
      } else {
        toast.update(id, {
          render: "something went wrong! please try again",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <div className="w-96 h-96">
      <div className="w-full h-14 flex justify-between items-center px-5  border-b border-gray-300">
        <h1 className="text-lg text-black font-medium">Add Wallet</h1>
        <GrClose
          className="text-lg font-medium text-heading"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <Formik
        initialValues={{
          icon: selectedIcon,
          name: "",
          currency: "",
          initial_amount: "",
          agree: false,
        }}
        onSubmit={onsubmit}
        validationSchema={WalletValidation}
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <div className="flex items-center justify-between py-4 px-3">
              <InputCard
                className="w-24 "
                error={errors.icon ? true : false}
                onClick={() => {
                  setShowIconModal(!showIconModal);
                }}
              >
                {values.icon ? (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <Image path={values.icon?.icon_name} />
                    <BsChevronDown className="text-base" />
                  </div>
                ) : (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <div className="w-10 h-10 bg-slate-100 rounded-full flex items-center justify-center">
                      <img
                        src="/assets/images/wallet.png"
                        className="w-6 h-6 "
                      />
                    </div>
                    <BsChevronDown className="text-base" />
                  </div>
                )}
              </InputCard>
              <InputCard
                className="w-64"
                title="Wallet Name"
                error={errors.name ? true : false}
              >
                <Field
                  type="text"
                  name="name"
                  className="w-full h-full text-xs font-normal outline-none"
                  placeholder="Your Wallet Name"
                  autoComplete="off"
                />
              </InputCard>
            </div>
            <div className="flex items-center justify-between py-1 px-3">
              <InputCard
                className="w-48 "
                error={errors.currency ? true : false}
                title="Currency"
                onClick={() => {
                  setShowCurrencyModal(!showCurrencyModal);
                }}
              >
                {values.currency ? (
                  <CurrencyInputCard currency={values.currency} />
                ) : null}
              </InputCard>
              <InputCard
                className="w-40"
                title="Initial Balance"
                error={errors.initial_amount ? true : false}
              >
                <Field
                  type="text"
                  name="initial_amount"
                  className="w-full h-full text-sm font-normal outline-none"
                  placeholder="0"
                  autoComplete="off"
                />
              </InputCard>
            </div>
            <div className="px-4 py-2">
              <div className="flex items-start my-3">
                <div className="flex items-center h-5">
                  <input
                    name="agree"
                    id="agree"
                    type="checkbox"
                    className="form-checkbox w-4 h-4 mt-2 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
                  />
                </div>

                <div className="ml-3 ">
                  <label
                    htmlFor="agree"
                    className=" text-xs font-medium text-gray-800"
                  >
                    Exclude from Total
                  </label>
                  <div className=" text-2xs font-normal text-secondryText">
                    Ignore this wallet and its balance in the 'Total' mode
                  </div>
                </div>
              </div>
            </div>
            <div className="float-right mt-4 p-3">
              <Button
                type="button"
                variant="smoke"
                className="mr-4"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </Button>
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </div>
            {showCurrencyModal ? (
              <Modal
                open={showCurrencyModal}
                onClose={() => setShowCurrencyModal(!showCurrencyModal)}
                useOutsideClick={false}
              >
                <CurrencySelectionModal
                  onSelectedCurrency={(values: any) => {
                    setFieldValue("currency", values);
                  }}
                  onClick={() => {
                    setShowCurrencyModal(!showCurrencyModal);
                  }}
                />
              </Modal>
            ) : null}

            {showIconModal ? (
              <Modal
                open={showIconModal}
                onClose={() => setShowIconModal(!showIconModal)}
                useOutsideClick={false}
              >
                <IconSelectModal
                  data={icons}
                  onSelectedIcon={(values: any) => {
                    setFieldValue("icon", values);
                  }}
                  onClick={() => {
                    setShowIconModal(!showIconModal);
                  }}
                />
              </Modal>
            ) : null}
          </Form>
        )}
      </Formik>

      <Modal
        open={showIconModal}
        onClose={() => setShowIconModal(!showIconModal)}
        useOutsideClick={false}
      >
        <IconSelectModal
          data={icons}
          onSelectedIcon={onSelectedIcon}
          onClick={() => {
            setShowIconModal(!showIconModal);
          }}
        />
      </Modal>
    </div>
  );
}
