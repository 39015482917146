import TransactionDetailes from "components/Transaction/Transacttion-detailes";
import Button from "components/ui/buttton";
import Image from "components/ui/Image";
import Tabs from "components/ui/Tab";
import WalletDropDown from "components/ui/WalletDropDown";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { useCurrencyQuery } from "framework/basic-rest/currency/get-currency";
import { useTransactionQuery } from "framework/basic-rest/transaction/get-transaction";
import { values } from "lodash";
import moment from "moment";
import { createContext, FC, useEffect, useMemo, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

interface IProps {}

/**
 * @author
 * @function @TransactionListPage
 **/

export const transactionContext = createContext(null);

export const TransactionsPage: FC<IProps> = (props) => {
  const { setModalData, openModal, setModalView } = useUI();
  const {
    wallet,
    initialWallet,
    selectedTransaction,
    setInitialWallet,
    platform,
  } = useApplication();

  const onHandleClick = (values: any) => {
    setInitialWallet(values);
  };

  if (platform == "DESKTOP") {
    return (
      <transactionContext.Provider value={initialWallet?._id}>
        <div className="h-[90%] w-full flex flex-col fixed">
          <div className="bg-white shadow w-full h-16 z-30">
            <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <h1 className=" text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
                Transactions
              </h1>
              <div className="flex items-center">
                <WalletDropDown
                  onClick={onHandleClick}
                  wallet={wallet}
                  selectedOption={initialWallet}
                >
                  <div className="w-14 h-10 flex items-center justify-between ">
                    <div className="w-8 h-8 bg-slate-700 rounded-full flex items-center justify-center">
                      <Image path={initialWallet.icon_name} size={"sm"} />
                    </div>
                    <AiFillCaretDown className="text-xs text-secondryText" />
                  </div>
                </WalletDropDown>
                <Button
                  className="ml-6"
                  onClick={() => {
                    openModal();
                    setModalData({
                      wallet: initialWallet,
                    });
                    setModalView("ADD_TRANSACTION");
                  }}
                >
                  ADD TRANSACTION
                </Button>
              </div>
            </div>
          </div>
          <div className="flex overflow-hidden z-10">
            <div className="h-full w-full pb-10 relative overflow-y-auto z-auto ">
              <main className="flex items-start justify-center p-5 lg:p-10 ">
                <div className=" snap-start w-3/6 lg:w-3/6 min-h-max bg-white rounded-sm mr2 flex justify-center shadow-xl">
                  <Tabs />
                </div>
                {selectedTransaction ? (
                  <TransactionDetailes data={selectedTransaction} />
                ) : null}
              </main>
            </div>
          </div>
        </div>
      </transactionContext.Provider>
    );
  }

  return (
    <transactionContext.Provider value={initialWallet?._id}>
      <div className="h-[90%] w-full flex flex-col fixed">
        <div className="bg-white shadow w-full h-16 z-30">
          <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className=" text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
              Transactions
            </h1>
            <div className="flex items-center">
              <WalletDropDown
                onClick={onHandleClick}
                wallet={wallet}
                selectedOption={initialWallet}
              >
                <div className="w-14 h-10 flex items-center justify-between ">
                  <div className="w-8 h-8 bg-slate-700 rounded-full flex items-center justify-center">
                    <Image path={initialWallet.icon_name} size={"sm"} />
                  </div>
                  <AiFillCaretDown className="text-xs text-secondryText" />
                </div>
              </WalletDropDown>
              <Button
                className="ml-6"
                onClick={() => {
                  openModal();
                  setModalData({
                    wallet: initialWallet,
                  });
                  setModalView("ADD_TRANSACTION");
                }}
              >
                ADD TRANSACTION
              </Button>
            </div>
          </div>
        </div>
        <div className="flex overflow-hidden z-10">
          <div className="h-full w-full pb-10 relative overflow-y-auto z-auto ">
            <main className="flex items-start justify-center p-5 lg:p-10 ">
              {selectedTransaction ? (
                <TransactionDetailes data={selectedTransaction} />
              ) : (
                <div className=" snap-start w-full md:w-3/6 lg:w-3/6 min-h-max bg-white rounded-sm mr2 flex justify-center shadow-xl">
                  <Tabs />
                </div>
              )}
            </main>
          </div>
        </div>
      </div>
    </transactionContext.Provider>
  );
};

export default TransactionsPage;
