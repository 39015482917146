import Modal from "components/common/modal/modal";
import Button from "components/ui/buttton";
import InputCard from "components/ui/InputCard";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import CurrencyInputCard from "./CurrencyInputCard";
import CurrencySelectionModal from "./CurrencySelectionModal";
import IconSelectModal from "./IconSelectModal";

type Props = {};

export default function EditWallet({}: Props) {
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);
  const [showIconModal, setShowIconModal] = useState<boolean>(false);

  const { closeModal, modalData } = useUI();
  const { icons, currency } = useApplication();

  const { data, walletRefetch } = modalData;

  const [selectedIcon, setSelectedIcon] = useState<any>(
    icons?.find((icon: any) => icon.icon_name == data.icon_name)
  );

  const [selectedCurrency, setSelectedCurrency] = useState<any>(
    currency?.find((currency: any) => currency.code == data.currency_code)
  );

  const onSelectedIcon = useCallback(
    (values: any) => {
      setSelectedIcon(values);
    },
    [selectedIcon]
  );
  const onSelectedCurrency = useCallback(
    (values: any) => {
      setSelectedCurrency(values);
    },
    [selectedCurrency]
  );

  const onsubmit = (values: any) => {
    const final_value = {
      name: values.name,
      icon_name: values.icon.icon_name,
      currency_code: values.currency.code,
    };
  };

  return (
    <div className="w-96 h-96">
      <div className="w-full h-14 flex justify-between items-center px-5  border-b border-gray-300">
        <h1 className="text-lg text-black font-medium">Edit Wallet</h1>
        <GrClose
          className="text-lg font-medium text-heading"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <Formik
        initialValues={{
          icon: selectedIcon,
          name: "",
          currency: selectedCurrency,
          initial_amount: "",
          agree: false,
          ...data,
        }}
        onSubmit={onsubmit}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <div className="flex items-center justify-between py-4 px-3">
              <InputCard
                className="w-24 "
                onClick={() => {
                  setShowIconModal(!showIconModal);
                }}
              >
                {selectedIcon ? (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <div className="w-9 h-9 rounded-full flex items-center justify-center">
                      <img
                        src={`/assets/${selectedIcon?.location_id}/${selectedIcon?.icon_name}`}
                        className="w-full h-full "
                      />
                    </div>
                    <BsChevronDown className="text-base" />
                  </div>
                ) : (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <div className="w-10 h-10 bg-slate-100 rounded-full flex items-center justify-center">
                      <img
                        src="/assets/images/wallet.png"
                        className="w-6 h-6 "
                      />
                    </div>
                    <BsChevronDown className="text-base" />
                  </div>
                )}
              </InputCard>
              <InputCard className="w-64" title="Wallet Name">
                <Field
                  type="text"
                  name="name"
                  className="w-full h-full text-xs font-normal outline-none"
                  placeholder="Your Wallet Name"
                />
              </InputCard>
            </div>
            <div className="flex items-center justify-between py-1 px-3">
              <InputCard
                className="w-48 "
                title="Currency"
                onClick={() => {
                  setShowCurrencyModal(!showCurrencyModal);
                }}
              >
                {selectedCurrency ? (
                  <CurrencyInputCard currency={selectedCurrency} />
                ) : null}
              </InputCard>
              <InputCard className="w-40" title="Initial Balance">
                <Field
                  type="text"
                  name="initial_amount"
                  className="w-full h-full text-sm font-normal outline-none"
                  placeholder="0"
                />
              </InputCard>
            </div>
            <div className="px-4 py-2">
              <div className="flex items-start my-3">
                <div className="flex items-center h-5">
                  <input
                    name="agree"
                    id="agree"
                    type="checkbox"
                    className="form-checkbox w-4 h-4 mt-2 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
                  />
                </div>

                <div className="ml-3 ">
                  <label
                    htmlFor="agree"
                    className=" text-xs font-medium text-gray-800"
                  >
                    Exclude from Total
                  </label>
                  <div className=" text-2xs font-normal text-secondryText">
                    Ignore this wallet and its balance in the 'Total' mode
                  </div>
                </div>
              </div>
            </div>
            <div className="float-right mt-4 p-3">
              <Button
                type="button"
                variant="smoke"
                className="mr-4"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </Button>
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={showCurrencyModal}
        onClose={() => setShowCurrencyModal(!showCurrencyModal)}
        useOutsideClick={false}
      >
        <CurrencySelectionModal
          onSelectedCurrency={onSelectedCurrency}
          onClick={() => {
            setShowCurrencyModal(!showCurrencyModal);
          }}
        />
      </Modal>
      <Modal
        open={showIconModal}
        onClose={() => setShowIconModal(!showIconModal)}
        useOutsideClick={false}
      >
        <IconSelectModal
          data={icons}
          onSelectedIcon={onSelectedIcon}
          onClick={() => {
            setShowIconModal(!showIconModal);
          }}
        />
      </Modal>
    </div>
  );
}
