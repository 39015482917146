import Button from "components/ui/buttton";
import WalletDetails from "components/wallet/WalletDetails";
import WalletList from "components/wallet/WalletList";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { FC, useState } from "react";

interface IProps {}

/**
 * @author
 * @function @ProfilePage
 **/

export const WalletPage: FC<IProps> = (props) => {
  const [walletData, setWalletData] = useState<any>(null);
  const { wallet, platform } = useApplication();
  const { openModal, setModalView } = useUI();

  const onHandleData = (values: any) => {
    setWalletData(values);
  };
  const onDetailClose = () => {
    setWalletData(null);
  };

  if (platform == "DESKTOP") {
    return (
      <div className="h-[90%] w-full flex flex-col fixed">
        <div className="bg-white shadow w-full h-16 z-10">
          <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className=" text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
              My Wallet
            </h1>
            <Button
              onClick={() => {
                openModal();
                setModalView("ADD_WALLET");
              }}
            >
              ADD WALLET
            </Button>
          </div>
        </div>
        <div className="flex overflow-hidden z-10">
          <div className="h-full w-full relative overflow-y-auto z-auto">
            <main className="flex w-full items-start justify-center p-5 lg:p-10">
              <WalletList onHandleData={onHandleData} wallets={wallet} />

              {walletData ? (
                <WalletDetails data={walletData} onClose={onDetailClose} />
              ) : null}
            </main>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[90%] w-full flex flex-col fixed">
      <div className="bg-white shadow w-full h-16 z-10">
        <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className=" text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
            My Wallet
          </h1>
          <Button
            onClick={() => {
              openModal();
              setModalView("ADD_WALLET");
            }}
          >
            ADD WALLET
          </Button>
        </div>
      </div>
      <div className="flex overflow-hidden z-10">
        <div className="h-full w-full relative overflow-y-auto z-auto">
          <main className="flex w-full items-start justify-center p-5 lg:p-10">
            {walletData ? (
              <WalletDetails data={walletData} onClose={onDetailClose} />
            ) : (
              <WalletList onHandleData={onHandleData} wallets={wallet} />
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default WalletPage;
