import groupBy from "lodash/groupBy";
import React, { useState } from "react";

type Props = {
  data?: any;
};

export default function TotalFlow({ data }: Props) {

  const income = data?.filter((data: any) => {
    if (data.category.type == 1) {
      return true;
    }
  });
  const expense = data?.filter((data: any) => {
    if (data.category.type == 2) {
      return true;
    }
  });

  const incomeAmount = income?.reduce((sum: number, current: any) => {
    return sum + current.amount;
  }, 0);
  const expenseAmount = expense?.reduce((sum: number, current: any) => {
    return sum + current.amount;
  }, 0);

  const totalAmount = incomeAmount - expenseAmount;

  return (
    <div className="w-full min-h-max bg-white p-4">
      <div className="w-full flex items-center justify-between my-1">
        <span className="text-xs ">inflow</span>
        <span className="text-xs text-blue-400">+₹{incomeAmount}</span>
      </div>
      <div className="w-full flex items-center justify-between my-1">
        <span className="text-xs">outflow</span>
        <span className="text-xs text-red-500">-₹{expenseAmount}</span>
      </div>
      <div className="w-32 h-0.5 border-b border-gray-300  float-right" />
      <div className="w-full flex justify-end">
        <p className="text-sm text-black my-1">₹{totalAmount}</p>
      </div>
      <div className=" text-sm font-medium text-primary flex justify-center cursor-pointer my-2">
        VIEW REPORT FOR THIS PERIOD
      </div>
    </div>
  );
}
