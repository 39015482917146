import { FC } from "react";
import Button from "components/ui/buttton";
import { Formik, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import SocialMediaGroup from "components/ui/SocialMediaGroup";
import { useSignUpMutation } from "framework/basic-rest/auth/user-signup";
import { toast } from "react-toastify";
import { UserFormRegistrationValidation } from "validation-schema/user-yup-schema";
interface IProps {}

export const RegisterPage: FC<IProps> = (props) => {
  const navigate = useNavigate();

  const registerMutation = useSignUpMutation();

  const onsubmit = async (values: any, setErrors: any) => {
    const id = toast.loading("Please Wait");

    try {
      await registerMutation.mutateAsync(values);
      toast.update(id, {
        render: "Register Success",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigate("/login");
    } catch (error: any) {
      if (error?.response) {
        if (error?.response.status == 422) {
          setErrors(error.response.data.data);
          toast.update(id, {
            render: "something went wrong! please try again",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }
      } else {
        toast.update(id, {
          render: "something went wrong! please try again",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <>
      <section className="w-screen h-screen flex justify-center items-center  bg-login-bg bg-top">
        <div className="w-[700px] h-auto bg-white rounded-xl px-6 py-3 shadow-2xl">
          <div className="flex justify-center items-center">
            {" "}
            <h1 className="text-center text-3xl font-semibold text-registration-header my-5">
              Register
            </h1>
          </div>
          <div className="md:flex md:items-start w-full px-2 py-4">
            <div className=" w-full md:w-1/2 px-4 md:px-7 py-2 ">
              <SocialMediaGroup />
            </div>
            <div className=" w-full md:w-1/2 px-4 md:px-7 py-2 md:border-l-2 border-gray-300">
              <div className="my-3 px-1">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    password: "",
                  }}
                  onSubmit={onsubmit}
                  validationSchema={UserFormRegistrationValidation}
                >
                  {({
                    values,
                    setFieldValue,
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {/* <!-- Email input --> */}
                      <div className="mb-6">
                        <Field
                          name="name"
                          type="text"
                          placeholder={"Name"}
                          autoComplete="off"
                          className={`h-12 w-full bg-input text-sm font-normal rounded-lg hover:outline hover:outline-primary focus:outline-primary focus:bg-white outline-offset-0 p-2 outline-none`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-xs text-red-500 pt-1"
                        />
                      </div>
                      <div className="mb-6">
                        <Field
                          name="email"
                          type="email"
                          placeholder={"Email"}
                          autoComplete="off"
                          className={`h-12 w-full bg-input text-sm font-normal rounded-lg hover:outline hover:outline-primary focus:outline-primary focus:bg-white outline-offset-0 p-2 outline-none`}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-xs text-red-500 pt-1"
                        />
                      </div>
                      <div className="mb-6">
                        <Field
                          name="password"
                          type={"password"}
                          placeholder={"Password"}
                          autoComplete="off"
                          className={`h-12 w-full bg-input text-sm font-normal rounded-lg hover:outline hover:outline-primary outline-offset-0 focus:outline-primary focus:bg-white p-2 outline-none`}
                        ></Field>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-xs text-red-500 pt-1"
                        />
                      </div>

                      <Button
                        className="w-full mb-2"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        REGISTER
                      </Button>

                      <p className="text-sm font-semibold mt-2 pt-1 mb-0 tracking-wide text-center my-4">
                        <span>
                          {" "}
                          <a>Have you an account?</a>
                        </span>
                        <Link to={"/login"}>
                          <span className="text-base font-medium text-primary hover:text-secondry hover:underline px-1">
                            Sing in
                          </span>
                        </Link>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default RegisterPage;

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   const { req, res } = context;
//   const session = await getSession({ req: req });

//   if (session?.user) {
//     res.writeHead(302, {
//       Location: "/app",
//     });
//     res.end();
//     return {
//       props: {},
//     };
//   }

//   return {
//     props: {},
//   };
// };
