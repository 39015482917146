import React from "react";
import { BsChevronRight } from "react-icons/bs";
import Image from "./Image";

type Props = {
  wallet?: any;
};

export default function WalletSelectedCard({ wallet }: Props) {
  return (
    <div className="flex justify-between items-center h-6 w-full mt-1">
      <div className="flex items-center">
        {wallet.icon_name ? (
          <Image path={wallet.icon_name} size="sm" />
        ) : (
          <Image path={wallet.icon_id} size="sm" />
        )}
        <span className="text-xs font-normal text-gray-500 pl-3">
          {wallet?.name}
        </span>
      </div>
      <BsChevronRight className="text-base" />
    </div>
  );
}
