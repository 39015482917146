import React from "react";
import { BsWindows } from "react-icons/bs";
import { GrClose } from "react-icons/gr";

type Props = {};

export default function DeviceCard({}: Props) {
  return (
    <div className="w-full min-h-[50px] flex items-center px-4 hover:bg-green-100  border-gray-400">
      <div className="w-9 h-9 rounded-full flex items-center justify-center bg-orange-500 py-4">
        {/* {type == "Mobile" ? (
          <BsWindows className="text-base text-white" />
        ) : (
          <BsWindows className="text-base text-white" />
        )} */}

        <BsWindows className="text-sm text-white" />
      </div>
      <div className="py-4 border-b w-full px-3 flex items-center justify-between">
        <div>
          <p className="text-xs font-normal text-black">Web Browser</p>
          <p className="text-2xs font-normal text-secondryText ">This Device</p>
        </div>
        <GrClose className="text-xs text-secondryText" />
      </div>
    </div>
  );
}
