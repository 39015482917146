import { useUI } from "contexts/ui.context";
import Modal from "./modal";
import React from "react";
import AddTransaction from "components/Transaction/AddTransaction";
import AddWallet from "components/wallet/AddWallet";
import EditWallet from "components/wallet/EditWallet";
import AdjustBalance from "components/wallet/AdjustBalanceModal";
import MyAccoutModal from "components/accout/MyAccoutModal";
import AddCategory from "components/category/AddCategory";
import EditCategory from "components/category/EditCategory";
import EditTransaction from "components/Transaction/EditTransactiton";

// const AddTransaction = React.lazy(
//   () => import("components/Transaction/AddTransaction")
// );

const ManagedModal: React.FC = () => {
  const { displayModal, closeModal, modalView } = useUI();
  return (
    <Modal open={displayModal} onClose={closeModal} useOutsideClick={false}>
      {modalView === "ADD_TRANSACTION" && <AddTransaction />}
      {modalView === "EDIT_TRANSACTION" && <EditTransaction />}
      {modalView === "ADD_WALLET" && <AddWallet />}
      {modalView === "EDIT_WALLET" && <EditWallet />}
      {modalView === "ADJUST_BALANCE" && <AdjustBalance />}
      {modalView === "ADD_CATEGORY" && <AddCategory />}
      {modalView === "EDIT_CATEGORY" && <EditCategory />}
      {modalView === "MY_ACCOUT" && <MyAccoutModal />}
    </Modal>
  );
};

export default ManagedModal;
