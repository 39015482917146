import * as Yup from "yup";

export const UserFormRegistrationValidation = Yup.object().shape({
 
    name: Yup.string()
      .required()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .matches(/^[a-z-A-Z-0-9_]+$/, "Invalid Format")
      .label("Name"),

    email: Yup.string()
        .email()
        .required("Email must be a valid email")
        .typeError("Email must be a valid email")
        .label("Email"),

    password: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required()
        .label("Password"),
});

export const UserLoginFormValidation = Yup.object().shape({
    username: Yup.string()
    .min(2, "Too Short!")
    .max(75, "Too Long!")
    .email()
    .required()
    .label("username"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required()
    .label("Password"),
 
});
