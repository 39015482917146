import { FC, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppHeader from "./headers/AppHeader";
import { useWalletsQuery } from "framework/basic-rest/wallet/get-wallets";
import { useApplication } from "contexts/application/application.context";
import { useCurrencyQuery } from "framework/basic-rest/currency/get-currency";
import { useIconsQuery } from "framework/basic-rest/icons/get-icons";
import AddWalletPage from "pages/onboard/AddWallet";

interface IProps {}

/**
 * @author
 * @function @AppLayout
 **/

export const AppLayout: FC<IProps> = (props) => {
  const { data, isLoading } = useWalletsQuery();
  const currency = useCurrencyQuery();
  const icons = useIconsQuery();

  const { wallet } = useApplication();

  if (wallet.length == 0 && !isLoading) {
    return <AddWalletPage />;
  }
  return (
    <>
      {!isLoading ? (
        <div className="min-h-screen flex flex-col">
          <AppHeader />

          <main className="h-1/2">
            <div className="flex overflow-hidden pt-16">
              {/* Replace with your content */}
              <div className="h-full w-full relative overflow-y-auto z-auto">
                <Outlet />
              </div>
              {/* /End replace */}
            </div>
          </main>
        </div>
      ) : (
        <div className="w-screen h-screen flex items-center justify-center">
          <svg
            className="animate-spin  h-12 w-12 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-50"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </>
  );
};
