import React from "react";
import { Tab } from "@headlessui/react";
import cn from "classnames";
import groupBy from "lodash/groupBy";

type Props = {
  onClick?: any;
  category?: any;
  onSelectedCategory?: any;
};

export default function CategoryTab({
  onClick,
  category,
  onSelectedCategory,
}: Props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const categoryObj = groupBy(category, "type");

  return (
    <>
      <div className="w-auto max-w-md sm:px-0 overflow-y-auto h-full">
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(index) => {
            setSelectedIndex(index);
          }}
        >
          <Tab.List className="flex space-x-1 border-b border-gray-200 sticky top-0 z-10 bg-white">
            {Object.keys(categoryObj)
              .reverse()
              .map((category) => (
                <Tab
                  key={category}
                  className={({ selected }: any) =>
                    cn(
                      "w-full   text-base font-medium leading-5 text-heading outline-none p-3 lg:px-5 lg:py-4",
                      selected ? "text-primary border-b-2 border-primary" : ""
                    )
                  }
                >
                  {category == "1" ? "Income" : "Expense"}
                </Tab>
              ))}
          </Tab.List>
          <Tab.Panels className="w-full">
            {Object.values(categoryObj)
              .reverse()
              .map((posts, idx) => (
                <Tab.Panel key={idx} className="p-2 ">
                  <ul>
                    {posts.map((post) => (
                      <li key={post._id}>
                        <div
                          className="w-full h-14 border-b border-gray-300 px-4  hover:bg-green-100 flex items-center cursor-pointer"
                          onClick={() => {
                            if (typeof onClick == "function") {
                              onClick();
                            }
                            if (typeof onSelectedCategory == "function") {
                              onSelectedCategory(post);
                            }
                          }}
                        >
                          <div className="w-10 h-10 rounded-full flex items-center justify-center bg-slate-200 p-2">
                            <img
                              src="/assets/images/google.png"
                              alt="wallet"
                              className="w-7 h-6 "
                            />
                          </div>
                          <span className="text-sm font-normal text-black pl-4">
                            {post?.name}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
