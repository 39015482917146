import Category from "components/category/Category";
import CateGoryDetailes from "components/category/CateGoryDetailes";
import Button from "components/ui/buttton";
import WalletDropDown from "components/ui/WalletDropDown";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { useCategoryQuery } from "framework/basic-rest/category/get-categorys";
import { FC, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

interface IProps {}

/**
 * @author
 * @function @CategoryListPage
 **/

export const CategoryPage: FC<IProps> = (props) => {
  const [categoryData, setCategoryData] = useState<any>(null);
  const [walletId, setWalletId] = useState<any>();
  const { openModal, setModalView,setModalData} = useUI();
  const { wallet, initialWallet, platform } = useApplication();
  const { data,refetch } = useCategoryQuery(walletId ? walletId : initialWallet?._id);

  const onHandleData = (values: any) => {
    setCategoryData(values);
  };

  const onDetailClose = () => {
    setCategoryData(null);
  };

  const onHandleClick = (values: any) => {
    setWalletId(values._id);
  };

  if (platform == "DESKTOP") {
    return (
      <div className="h-[90%] w-full flex flex-col fixed">
        <div className="bg-white shadow w-full h-16 z-30">
          <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className="text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
              Category
            </h1>
            <div className="flex items-center">
              <WalletDropDown
                onClick={onHandleClick}
                wallet={wallet}
                selectedOption={wallet?.find(
                  (wallet: any) => wallet._id == initialWallet?._id
                )}
              >
                <div className="w-14 h-10 flex items-center justify-between">
                  <div className="w-8 h-8 bg-slate-700 rounded-full flex items-center justify-center">
                    <img src="/assets/images/wallet.png" className="w-4 h-4" />
                  </div>
                  <AiFillCaretDown className="text-xs text-secondryText" />
                </div>
              </WalletDropDown>
              <Button
                className="ml-6"
                onClick={() => {
                  setModalData({
                    categoryRefetch:refetch
                  })
                  openModal();
                  setModalView("ADD_CATEGORY");
                }}
              >
                ADD CATEGORY
              </Button>
            </div>
          </div>
        </div>
        <div className="flex overflow-hidden z-10">
          <div className="h-full w-full relative overflow-y-auto z-auto">
            <main className="flex w-full items-start justify-center p-5 lg:p-10">
              <Category category={data} onHandleData={onHandleData} />

              {categoryData ? (
                <CateGoryDetailes data={categoryData} onClose={onDetailClose} />
              ) : null}
            </main>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[90%] w-full flex flex-col fixed">
      <div className="bg-white shadow w-full h-16 z-30">
        <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
            Category
          </h1>
          <div className="flex items-center">
            <WalletDropDown
              onClick={onHandleClick}
              wallet={wallet}
              selectedOption={wallet?.find(
                (wallet: any) => wallet._id == initialWallet?._id
              )}
            >
              <div className="w-14 h-10 flex items-center justify-between">
                <div className="w-8 h-8 bg-slate-700 rounded-full flex items-center justify-center">
                  <img src="/assets/images/wallet.png" className="w-4 h-4" />
                </div>
                <AiFillCaretDown className="text-xs text-secondryText" />
              </div>
            </WalletDropDown>
            <Button
              className="ml-6"
              onClick={() => {
                openModal();
                setModalView("ADD_CATEGORY");
              }}
            >
              ADD CATEGORY
            </Button>
          </div>
        </div>
      </div>
      <div className="flex overflow-hidden z-10">
        <div className="h-full w-full relative overflow-y-auto z-auto">
          <main className="flex w-full items-start justify-center p-5 lg:p-10">
            {categoryData ? (
              <CateGoryDetailes data={categoryData} onClose={onDetailClose} />
            ) : (
              <Category category={data} onHandleData={onHandleData} />
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
