import React, { ReactNode, useEffect, useReducer, useRef } from "react";
import useMobileDetect from "utils/use-mobile-detect";
import { State, applicationReducer, initialState } from "./application.reducer";

interface ApplicationProviderState extends State {
  setWallet: (query: any) => void;
  setCurrency: (query: any) => void;
  setIcons: (query: any) => void;
  setInitialWallet: (query: any) => void;
  setSelectedTransaction: (query: any) => void;
}

export const applicationContext = React.createContext<
  ApplicationProviderState | undefined
>(undefined);
applicationContext.displayName = "ApplicationContext";

export const useApplication = () => {
  const context = React.useContext(applicationContext);
  if (context === undefined) {
    throw new Error(`useApplication must be used within a ApplicationProvider`);
  }
  return context;
};

interface BaseLayoutProps {
  children?: ReactNode;
}

export const ApplicationProvider: React.FC<BaseLayoutProps> = (props) => {
  const [state, dispatch] = useReducer(applicationReducer, initialState);

  const detectMobile = useMobileDetect();

  useEffect(() => {
    if (detectMobile.isDesktop()) {
      dispatch({
        type: "SET_PLATFORM",
        payload: "DESKTOP",
      });
    } else {
      dispatch({
        type: "SET_PLATFORM",
        payload: "MOBILE",
      });
    }
  }, []);

  const setWallet = (query: any) => {
    dispatch({
      type: "SET_WALLET",
      payload: query,
    });
  };
  const setCurrency = (query: any) => {
    dispatch({
      type: "SET_CURRENCY",
      payload: query,
    });
  };
  const setIcons = (query: any) => {
    dispatch({
      type: "SET_ICONS",
      payload: query,
    });
  };
  const setInitialWallet = (query: any) => {
    dispatch({
      type: "SET_INITIAL_WALLET",
      payload: query,
    });
  };
  const setSelectedTransaction = (query: any) => {
    dispatch({
      type: "SET_TRANSACTION",
      payload: query,
    });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      setWallet,
      setIcons,
      setInitialWallet,
      setSelectedTransaction,
      setCurrency,
    }),
    [state]
  );

  return (
    <>
      <applicationContext.Provider value={value} {...props} />
    </>
  );
};
