import React, { FC } from "react";

interface IProps {}

/**
 * @author
 * @function @ProfilePage
 **/

export const ProfilePage: FC<IProps> = (props) => {
  return <div>ProfilePage</div>;
};

export default ProfilePage;
