import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import http from "../utils/http";

async function fetchAllCategory(wallet_id: string) {
  const { data } = await http.get(
    `${API_ENDPOINTS.CATEGORY_LIST}/${wallet_id}`
  );
  return data;
}

export const useCategoryQuery = (wallet_id: string) => {
  return useQuery([API_ENDPOINTS.CATEGORY_LIST, wallet_id], () =>
    fetchAllCategory(wallet_id)
  );
};
