import Modal from "components/common/modal/modal";
import WalletSelectionModal from "components/Transaction/WalletSelectionModal";
import Button from "components/ui/buttton";
import InputCard from "components/ui/InputCard";
import WalletSelectedCard from "components/ui/WalletSelectedCard";
import IconSelectModal from "components/wallet/IconSelectModal";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { Formik, Form, Field } from "formik";
import { useCallback, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GrClose } from "react-icons/gr";

type Props = {};

export default function EditCategory({}: Props) {
  const { closeModal, modalData } = useUI();
  const { icons, wallet } = useApplication();
  const { data } = modalData;
  const [showWalletModal, setshowWalletModal] = useState<boolean>(false);
  const [showIconModal, setShowIconModal] = useState<boolean>(false);
  const [selectedWallet, setSelectedWallet] = useState<any>(
    wallet?.find((wallet: any) => wallet._id == data.wallet_id)
  );
  const [selectedIcon, setSelectedIcon] = useState<any>(
    icons?.find((icon: any) => icon.icon_name == data.icon_id)
  );

  const onSelectedData = useCallback(
    (values: any) => {
      setSelectedWallet(values);
    },
    [selectedWallet]
  );

  const onSelectedIcon = useCallback(
    (values: any) => {
      setSelectedIcon(values);
    },
    [selectedIcon]
  );
  return (
    <div className="w-96 min-h-max">
      <div className="w-full h-14 flex justify-between items-center px-5  border-b border-gray-300">
        <h1 className="text-lg text-black font-medium">Edit Category</h1>
        <GrClose
          className="text-lg font-medium text-heading"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <Formik
        initialValues={{
          icon: selectedIcon,
          name: "",
          type: 1,
          wallet: selectedWallet,
          ...data,
        }}
        onSubmit={async (values: any) => {
          console.log(values)
        }}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <div className="flex items-center justify-between py-4 px-3">
              <InputCard
                className="w-24 "
                onClick={() => {
                  setShowIconModal(!showIconModal);
                }}
              >
                {selectedIcon ? (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <div className="w-9 h-9 rounded-full flex items-center justify-center">
                      <img
                        src={`/assets/${selectedIcon?.location_id}/${selectedIcon?.icon_name}`}
                        className="w-full h-full "
                      />
                    </div>
                    <BsChevronDown className="text-base" />
                  </div>
                ) : (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <div className="w-10 h-10 bg-slate-100 rounded-full flex items-center justify-center">
                      <img
                        src="/assets/images/wallet.png"
                        className="w-6 h-6 "
                      />
                    </div>
                    <BsChevronDown className="text-base" />
                  </div>
                )}
              </InputCard>
              <InputCard className="w-64" title="Category Name">
                <Field
                  type="text"
                  name="name"
                  autoComplete="off"
                  className="w-full h-full text-xs font-normal outline-none"
                  placeholder="category name"
                />
              </InputCard>
            </div>

            <div className="flex items-center px-6">
              <div className="mr-2">
                <input
                  type="radio"
                  id="income"
                  name="type"
                  value={1}
                  className="cursor-pointer"
                  onChange={() => {
                    setFieldValue("type", 1);
                  }}
                  checked={values.type == 1}
                />{" "}
                <label
                  htmlFor="income"
                  className="text-sm font-medium text-black px-1 cursor-pointer"
                >
                  Income
                </label>
              </div>
              <div className="ml-2">
                <input
                  type="radio"
                  id="expense"
                  name="type"
                  value={2}
                  className="cursor-pointer"
                  onChange={() => {
                    setFieldValue("type", 2);
                  }}
                  checked={values.type == 2}
                />{" "}
                <label
                  htmlFor="expense"
                  className="text-sm font-medium text-black px-1 cursor-pointer"
                >
                  Expense
                </label>
              </div>
            </div>

            <div className="px-5 py-4">
              <InputCard
                title="Wallet"
                className="w-full"
                onClick={() => {
                  setshowWalletModal(!showWalletModal);
                }}
              >
                {selectedWallet ? (
                  <WalletSelectedCard wallet={selectedWallet} />
                ) : null}
              </InputCard>
            </div>
            <div className="float-right mt-4 px-5 py-3">
              <Button
                type="button"
                variant="smoke"
                className="mr-4"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </Button>
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={showWalletModal}
        onClose={() => setshowWalletModal(!showWalletModal)}
        useOutsideClick={false}
      >
        <WalletSelectionModal
          onSelectedData={onSelectedData}
          onClick={() => {
            setshowWalletModal(!showWalletModal);
          }}
        />
      </Modal>

      <Modal
        open={showIconModal}
        onClose={() => setShowIconModal(!showIconModal)}
        useOutsideClick={false}
      >
        <IconSelectModal
          data={icons}
          onSelectedIcon={onSelectedIcon}
          onClick={() => {
            setShowIconModal(!showIconModal);
          }}
        />
      </Modal>
    </div>
  );
}
