type Props = {};

export default function PhotoUploadCard({}: Props) {
  return (
    <>
      <div className="w-full h-full">
        <p className="text-sm font-normal text-secondryText lg:p-1">
          Drop file attach,or{" "}
          <span className="font-medium text-primary">browse</span>
        </p>
        <p className="text-xs font-normal text-secondryText p-1">
          image file formats-JPG,JPEG,png,GIF
        </p>
        <p className="text-2xs font-normal text-secondryText lg:p-1">
          Image size less than 2MB
        </p>
      </div>
    </>
  );
}
