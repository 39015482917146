import SearchBar from "components/ui/SearchBar";
import { useApplication } from "contexts/application/application.context";
import React from "react";
import { GrClose } from "react-icons/gr";

type Props = {
  onClick?: any | null;
  onSelectedCurrency?: Function;
};

export default function CurrencySelectionModal({
  onClick,
  onSelectedCurrency,
}: Props) {
  const { currency } = useApplication();
  return (
    <div className="w-96 h-[400px] lg:h-[500px]">
      <div className="w-full border-b border-gray-300">
        <header className="w-full h-16 flex justify-between items-center">
          <div className="w-1/2 flex items-center justify-evenly">
            <GrClose className="text-lg text-heading" onClick={onClick} />
            <h1 className="text-lg font-medium text-heading">Currency</h1>
          </div>
          <div className="w-1/2 px-3">
            <SearchBar />
          </div>
        </header>
      </div>
      <div className=" grid grid-cols-2 gap-1 p-2">
        {currency?.map((currency: any) => {
          return (
            <div
              key={currency._id}
              className="h-14 w-full flex items-center hover:bg-green-100 px-2 py-1"
              onClick={() => {
                onClick();
                if (typeof onSelectedCurrency == "function") {
                  onSelectedCurrency(currency);
                }
              }}
            >
              <img
                src={`/assets/images/india.png`}
                alt={currency.name}
                className="w-11 h-full"
              />
              <div className="pl-3">
                <p className="text-sm font-medium text-heading">
                  {currency.name}
                </p>
                <p className="text-xs font-normal text-secondryText">
                  {currency.code}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
