import Image from "components/ui/Image";
import UsersList from "components/ui/UsersList";
import { useUI } from "contexts/ui.context";
import { useDeleteWalletMutation } from "framework/basic-rest/wallet/delete-wallet";
import { useWalletsQuery } from "framework/basic-rest/wallet/get-wallets";
import { GrClose } from "react-icons/gr";
import Swal from "sweetalert2";

type Props = {
  data?: any;
  onClose?: Function;
};

export default function WalletDetails({ data, onClose }: Props) {
  const { mutateAsync } = useDeleteWalletMutation();
  const { setModalView, openModal, setModalData } = useUI();
  const { refetch } = useWalletsQuery();

  const walletDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this Wallet?",
      showCancelButton: true,
      confirmButtonColor: "#0e9e2c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await mutateAsync({
          wallet_id: data._id,
        });
        refetch();
        if (typeof onClose === "function") {
          onClose();
        }

        Swal.fire("Deleted!", "Wallet has been deleted.", "success");
      }
    });
  };
  return (
    <div className=" w-full md:w-3/6 lg:w-4/6 min-h-[200px] bg-white rounded-sm shadow-lg top-0 ml-3 sticky overflow-y-auto bg-scroll">
      <div className="h-14 flex items-center justify-between border-b border-gray-300 px-5">
        <div className="flex items-center">
          <GrClose
            className="text-base lg:text-lg mr-2 cursor-pointer"
            onClick={() => {
              if (typeof onClose === "function") {
                onClose();
              }
            }}
          />
          <h1 className="text-base lg:text-lg font-medium text-heading px-5">
            Wallet details
          </h1>
        </div>
        <div>
          <span
            className="text-xs lg:text-sm text-red-600 font-medium mx-2 lg:mx-4 cursor-pointer"
            onClick={walletDelete}
          >
            DELETE
          </span>
          <span
            className="text-xs lg:text-sm text-primary font-medium mx-2 lg:mx-4 cursor-pointer"
            onClick={() => {
              setModalData({
                data: data,
                walletRefetch: refetch(),
              });
              setModalView("EDIT_WALLET");
              openModal();
            }}
          >
            EDIT
          </span>
        </div>
      </div>
      <div className="flex items-start px-5 lg:px-20 py-4 border-b border-gray-300">
       
        <Image path={data.icon_name} size="lg" />
        <div className="px-4">
          <p className="text-sm font-medium text-heading ">{data?.name}</p>
          <p className="text-xs font-normal text-placeholder">
            {data?.currency_code}
          </p>
        </div>
      </div>
      <div className="px-5 lg:px-20 py-2 border-b border-gray-300">
        <p className="text-xs font-normal text-secondryText ">Users</p>
        <UsersList users={data?.users} />
      </div>
      <div className="px-5 lg:px-20 py-1 border-b border-gray-300">
        <div>
          <div className="flex items-start my-3">
            <div className="flex items-center h-5">
              <input
                name="agree"
                id="agree"
                type="checkbox"
                className="form-checkbox w-4 h-4 mt-2 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
              />
            </div>

            <div className="ml-3 ">
              <label
                htmlFor="agree"
                className=" text-xs lg:text-sm font-medium text-gray-800"
              >
                Exclude from Total
              </label>
              <div className=" text-2xs lg:text-xs font-normal text-secondryText">
                Ignore this wallet and its balance in the 'Total' mode
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start my-3">
          <div className="flex items-center h-5">
            <input
              name="agree"
              id="agree"
              type="checkbox"
              className="form-checkbox w-4 h-4 mt-2 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
            />
          </div>

          <div className="ml-3 ">
            <label
              htmlFor="agree"
              className="text-xs lg:text-sm font-medium text-gray-800"
            >
              Archived
            </label>
            <div className="text-2xs lg:text-xs font-normal text-secondryText">
              Freeze This Wallet and stop generating bills & recurring
              transactions
            </div>
          </div>
        </div>
      </div>
      <div
        className="p-3 text-center text-primary font-medium text-xs hover:bg-green-100 cursor-pointer"
        onClick={() => {
          openModal();
          setModalView("ADJUST_BALANCE");
        }}
      >
        ADJUST BALANCE
      </div>
    </div>
  );
}
