import CategoryList from "components/ui/CategoryList";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";

type Props = {
  category?: any;
  onHandleData?: Function;
};

export default function Category({ category, onHandleData }: Props) {
  const categoryObj = groupBy(category, "type");

  return (
    <div className=" w-full md:w-3/6 lg:w-3/6 min-h-[100px] bg-white rounded-sm mr-2 shadow-lg">
      {Object.keys(categoryObj)
        .reverse()
        .map((category: any) => (
          <div key={category}>
            <div className="text-xs font-medium text-heading p-4 border-b border-gray-300 bg-gray-50">
              {category == 2 ? "Expense" : "Income"}
            </div>

            {categoryObj[category].map((category) => (
              <CategoryList
                key={category._id}
                category={category}
                handleCLick={() => {
                  if (typeof onHandleData == "function") {
                    onHandleData(category);
                  }
                }}
              />
            ))}
          </div>
        ))}

      <div></div>
    </div>
  );
}
