import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import http from "../utils/http";

interface TransactionFilterQuery {
  wallet_id?: string;
  start_date?: any;
  end_date?: any;
}

async function fetchTransactions(input: TransactionFilterQuery) {
  const { data } = await http.get(`${API_ENDPOINTS.TRANSACTION}`, {
    params: input,
  });
  return data;
}

export const useTransactionQuery = (dateFilter: TransactionFilterQuery) => {
  return useQuery([API_ENDPOINTS.TRANSACTION, dateFilter.wallet_id], () =>
    fetchTransactions(dateFilter)
  );
};
