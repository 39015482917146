type Action =
  | { type: "SET_WALLET"; payload: any }
  | { type: "SET_CURRENCY"; payload: any }
  | { type: "SET_ICONS"; payload: any }
  | { type: "SET_INITIAL_WALLET"; payload: any }
  | { type: "SET_TRANSACTION"; payload: any }
  | { type: "SET_PLATFORM"; payload: any };

export interface State {
  wallet: any;
  currency: any;
  icons: any;
  platform?: any;
  initialWallet: any | undefined;
  selectedTransaction: any;
}

export const initialState: State = {
  wallet: [],
  currency: null,
  icons: null,
  initialWallet: null,
  selectedTransaction: null,
};

export function applicationReducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_WALLET": {
      return {
        ...state,
        wallet: action.payload,
      };
    }
    case "SET_CURRENCY": {
      return {
        ...state,
        currency: action.payload,
      };
    }
    case "SET_ICONS": {
      return {
        ...state,
        icons: action.payload,
      };
    }
    case "SET_INITIAL_WALLET": {
      return {
        ...state,
        initialWallet: action.payload,
      };
    }
    case "SET_TRANSACTION": {
      return {
        ...state,
        selectedTransaction: action.payload,
      };
    }
    case "SET_PLATFORM": {
      return {
        ...state,
        platform: action.payload,
      };
    }

    default:
      return state;
  }
}
