import { useApplication } from "contexts/application/application.context";
import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import http from "../utils/http";

async function fetchIcons() {
  const { data } = await http.get(API_ENDPOINTS.ICONS);
  return data;
}

export const useIconsQuery = () => {
  const { setIcons } = useApplication();
  return useQuery([API_ENDPOINTS.ICONS], () => fetchIcons(), {
    onSuccess(data) {
      setIcons && setIcons(data);
     
    },
  });
};
