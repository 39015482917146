import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import http from "../utils/http";
import { API_ENDPOINTS } from "framework/basic-rest/utils/api-endpoints";

export interface TransactionInputType {
  wallet_id: String;
  category_id?: String;
  amount?: number;
  display_date?: string;
  note?: string;
}
async function addTransaction(input: TransactionInputType) {
  return http.post(API_ENDPOINTS.TRANSACTION, input);
}

export const useAddTransactionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((input: TransactionInputType) => addTransaction(input), {
    onSuccess: () => {
      queryClient.invalidateQueries([API_ENDPOINTS.WALLET_LIST]);
    },
  });
};
