import DeviceCard from "components/ui/DeviceCard";
import { useUI } from "contexts/ui.context";
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

type Props = {};

export default function MyAccoutModal({}: Props) {
 

  const signOut = () => {
    // localStorage.removeItem("ACCESS_TOKEN");
    // navigate("/login");
  };

  const { closeModal } = useUI();
  return (
    <div className="w-430px min-h-max">
      <div className="w-full h-16 flex justify-between items-center px-5  border-b border-gray-300">
        <div className="flex items-center">
          <GrClose
            className="text-lg font-medium text-heading"
            onClick={() => {
              closeModal();
            }}
          />
          <h1 className="text-lg text-black font-medium pl-6">My Accout</h1>
        </div>
        <div className="w-20 h-8 p-1 flex items-center justify-center rounded hover:bg-green-100">
          <p className="text-xs font-medium text-primary" onClick={signOut}>
            SIGN OUT
          </p>
        </div>
      </div>
      <div className="px-10 py-6 flex items-center border-b border-gray-300">
        <div className=" w-14 h-14 flex items-center justify-center text-2xl rounded-full bg-blue-500 text-white font-medium ">
          S
        </div>
        <div className="pl-6">
          <p className="text-sm font-medium text-black ">SathiyamoorthiOp</p>
          <p className="text-xs font-normal text-secondryText py-1">
            sathiyamoorthiop@gmail.com
          </p>
        </div>
      </div>
      <div className="py-3">
        <span className="text-xs font-normal text-secondryText py-2 px-3">{`Device (2/5)`}</span>
        <div className="py-2">
          <DeviceCard />
          <DeviceCard />
        </div>
      </div>
      <div className="w-full px-7 py-4 flex items-center justify-end">
        <p className="text-xs font-normal text-secondryText underline">
          Delete Accout
        </p>
      </div>
    </div>
  );
}
