import cn from "classnames";
import React from "react";

type Props = {
  path?: string;
  size?: "sm" | "md" | "lg";
};

export default function Image({ path, size = "md" }: Props) {
  const finalPath = path == "default" ? "ic_category_all.png" : path;

  return (
    <div>
      <img
        src={`/assets/icons/${finalPath}`}
        className={cn({
          "w-7 h-7": size == "sm",
          "w-10 h-10": size == "md",
          "w-14 h-14": size == "lg",
        })}
      />
    </div>
  );
}
