import * as Yup from "yup";

export const CategoryValidation = Yup.object().shape({
 
    icon: Yup.object()
      .required(),

    name: Yup.string()
      .required()
      .label("Name"),

    type: Yup.number()
        .required(),

    wallet: Yup.object()
        .required()
        
});