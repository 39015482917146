import * as Yup from "yup";

export const WalletValidation = Yup.object().shape({
 
    icon: Yup.object()
      .required(),

    name: Yup.string()
      .required()
      .label("Name"),

    currency: Yup.object()
      .required(),
      
    initial_amount: Yup.number()
        .required()
        .label("Initial Amount"),
});