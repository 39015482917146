import React from "react";
import Image from "./Image";

type Props = {
  handleCLick?: Function | null;
  category?: any;
};

export default function CategoryList({ category, handleCLick }: Props) {
  return (
    <div
      className="w-full h-14 border-b border-gray-300 px-4  hover:bg-green-100 flex items-center cursor-pointer"
      onClick={() => {
        if (typeof handleCLick == "function") {
          handleCLick(category);
        }
      }}
    >
      <Image path={category.icon_id} />
      <span className="text-sm font-normal text-black pl-4">
        {category?.name}
      </span>
    </div>
  );
}
