import React from "react";

type Props = {
  name?: string;
  image?: string;
  handleClick?: any;
};

export default function EventListCard({ name, image, handleClick }: Props) {
  return (
    <div
      className="w-full min-h-[60px] bg-white hover:bg-green-100 border-b border-gray-300 flex items-center px-5 py-3"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="w-10 h-10 rounded-full flex items-center justify-center bg-slate-300 p-2">
        <img src={image} alt="wallet" className="w-7 h-6 " />
      </div>
      <div className="px-4">
        <p className="text-xs font-medium text-black py-1">{name}</p>
      </div>
    </div>
  );
}
