import Image from "components/ui/Image";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { useDeleteTransactionMutation } from "framework/basic-rest/transaction/delete-transaction";
import moment from "moment";
import { GrClose } from "react-icons/gr";
import Swal from "sweetalert2";

type Props = {
  data?: any;
};

export default function TransactionDetailes({ data }: Props) {
  const { setModalView, openModal, setModalData } = useUI();
  const { setSelectedTransaction } = useApplication();

  const { mutateAsync } = useDeleteTransactionMutation();

  const transactionDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this Transaction?",
      showCancelButton: true,
      confirmButtonColor: "#0e9e2c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await mutateAsync({
          wallet_id: data.wallet._id,
          transaction_id: data._id,
        });
        // refetch();
        // if (typeof onClose === "function") {
        //   onClose();
        // }

        Swal.fire("Deleted!", "Transaction has been deleted.", "success");
      }
    });
  };

  return (
    <div className="w-full md:w-3/6 lg:w-4/6 min-h-[200px] bg-white rounded-sm shadow-lg sticky top-0 ml-3">
      <div className="h-14 flex items-center justify-between border-b border-gray-300 px-5">
        <div className="flex items-center">
          <GrClose
            className="text-base lg:text-lg mr-1 md:mr-2 cursor-pointer"
            onClick={() => {
              setSelectedTransaction(null);
            }}
          />
          <h1 className=" text-[14px] md:text-base lg:text-lg font-medium text-heading px-5">
            Transaction details
          </h1>
        </div>
        <div>
          <span
            className=" text-2xs md:text-xs lg:text-sm text-red-600 font-medium mx-2 cursor-pointer"
            onClick={transactionDelete}
          >
            DELETE
          </span>
          <span
            className="text-2xs md:text-xs lg:text-sm text-primary font-medium mx-2 cursor-pointer"
            onClick={() => {
              setModalData(data);
              setModalView("EDIT_TRANSACTION");
              openModal();
            }}
          >
            EDIT
          </span>
        </div>
      </div>
      <div className="flex items-start px-7 py-4">
        {data.icon_name == "default" ? (
          <div className="w-14 h-14 rounded-full bg-gray-200 flex items-center justify-center p-2">
            <img src="/assets/images/wallet.png" className="w-8 h-8" />
          </div>
        ) : (
          <Image path={data.category.icon_id} size="lg" />
        )}

        <div className="px-5">
          <h1 className="text-xl font-medium text-heading ">
            {data?.category?.name}
          </h1>
          <p className="text-[13px] font-normal text-black py-1">
            {data?.wallet?.name}
          </p>
          <p className="text-2xs font-light text-secondryText">
            {moment(data?.display_date).format("dddd")}{" "}
            {moment(data?.display_date).format("DD/MM/YYYY")}
          </p>

          <div className="w-40 h-0.5 border-b border-gray-300 my-3" />

          <div
            className={`text-xl font-medium ${
              data?.category?.type == 1 ? "text-blue-400" : "text-red-500"
            }`}
          >
            {data?.category.type == 1
              ? `+ ₹${data.amount}`
              : `- ₹${data.amount}`}
          </div>
        </div>
      </div>
    </div>
  );
}
