import Image from "components/ui/Image";
import { useApplication } from "contexts/application/application.context";
import React from "react";

type Props = {
  data?: any;
};

export default function TransactionCardList({ data }: Props) {
  const { setSelectedTransaction } = useApplication();

  return (
    <div
      className="w-full h-16  px-4  hover:bg-green-100 flex items-center justify-between cursor-pointer"
      onClick={() => {
        setSelectedTransaction(data);
      }}
    >
      <div className="flex items-center">
        {" "}
        {/* <div className="w-10 h-10 rounded-full flex items-center justify-center bg-slate-200 p-2">
          <img
            src={"/assets/images/education.png"}
            alt="wallet"
            className="w-7 h-6 "
          />
        </div> */}
        <Image path={data.category.icon_id} />
        <div className="flex flex-col">
          <span className="text-sm font-medium text-black pl-4">
            {data?.category?.name}
          </span>
          <span className="text-xs font-normal text-gray-400 pl-4">
            {data?.note}
          </span>
        </div>
      </div>
      <div
        className={`text-xs font-medium ${
          data?.category?.type == 1 ? "text-blue-400" : "text-red-500"
        }`}
      >
        {data?.category.type == 1 ? `+ ₹ ${data.amount}` : `- ₹ ${data.amount}`}
      </div>
    </div>
  );
}
