import { useApplication } from "contexts/application/application.context";
import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import http from "../utils/http";

async function fetchCurrency() {
  const { data } = await http.get(API_ENDPOINTS.CURRENCY);
  return data;
}

export const useCurrencyQuery = () => {
  const { setCurrency } = useApplication();
  return useQuery([API_ENDPOINTS.CURRENCY], () => fetchCurrency(), {
    onSuccess(data) {
      setCurrency && setCurrency(data);
    
    },
  });
};
