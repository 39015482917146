import axios from "axios";
const http = axios.create({
  baseURL: "/api",
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Change request data/error here
http.interceptors.request.use(
  (config) => {

    const newConfig:any = {};
      
    const token  = localStorage.getItem("ACCESS_TOKEN")
    if(token){
      newConfig["Authorization"] = `Bearer ${token}`
    }

    config.headers = {
      ...config.headers,
      ...newConfig
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
