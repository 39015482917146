import { GrClose } from "react-icons/gr";

type Props = { data?: any; onClick?: any; onSelectedIcon?: Function };

export default function IconSelectModal({
  data,
  onClick,
  onSelectedIcon,
}: Props) {
  return (
    <div className="w-96 h-[400px] lg:h-[500px]">
      <div className="w-full border-b border-gray-300">
        <header className="w-full h-16 flex justify-between items-center">
          <div className="w-1/2 flex items-center justify-evenly">
            <GrClose className="text-lg text-heading" onClick={onClick} />
            <h1 className="text-lg font-medium text-heading">Select Icon</h1>
          </div>
        </header>
      </div>
      <div className=" grid grid-cols-6 gap-1 p-2">
        {data?.map((icon: any) => {
          return (
            <div
              key={icon._id}
              className="h-14 w-full flex items-center hover:bg-green-100 px-2 py-2"
              onClick={() => {
                if (typeof onClick === "function") {
                  onClick();
                }
                if (typeof onSelectedIcon === "function") {
                  onSelectedIcon(icon);
                }
              }}
            >
              <div className="w-full h-full flex items-center justify-center rounded-full">
                <img
                  src={`/assets/${icon.location_id}/${icon.icon_name}`}
                  className="w-full h-full"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
