import * as Yup from "yup";

export const TransactionValidation = Yup.object().shape({
 
    wallet: Yup.object()
      .required()
      ,
    category: Yup.object()
      .required()
      ,
    amount: Yup.number()
        .required()
        .label("amount"),

    
    

    // email: Yup.string()
    //     .email()
    //     .required("Email must be a valid email")
    //     .typeError("Email must be a valid email")
    //     .label("Email"),

    // password: Yup.string()
    //     .min(2, "Too Short!")
    //     .max(50, "Too Long!")
    //     .required()
    //     .label("Password"),
});