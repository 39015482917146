import groupBy from "lodash/groupBy";
import moment from "moment";
import { useState } from "react";
import TransactionCardList from "./TransactionCardList";

type Props = {
  data?: any;
};

export default function TransactionList({ data }: Props) {
  const [total, setTotal] = useState<number>(0);
  const transactionObj = groupBy(data, "display_date");

  return (
    <>
      {Object.keys(transactionObj).map((transaction: any) => {
        return (
          <div key={transaction}>
            <div className="w-full h-4 bg-slate-100" />
            <div className="w-full min-h-max">
              <div className="w-full h-16 flex justify-between items-center px-3 py-2 border-b border-gray-300 sticky top-14 bg-white">
                <div className="flex items-center w-1/2">
                  <span className="text-3xl font-medium text-heading mr-3">
                    {moment(transaction).format("DD")}
                  </span>
                  <span>
                    <p className="text-xs font-medium text-secondryText">
                      {moment(transaction).format("dddd")}
                    </p>
                    <p className="text-2xs font-medium text-secondryText">
                      {moment(transaction).format("MMMM")}{" "}
                      {moment(transaction).format("YYYY")}
                    </p>
                  </span>
                </div>
                <div className="text-sm font-medium text-heading">
                  ₹
                  {transactionObj[transaction]?.reduce(
                    (sum: number, current: any) => {
                      if (current.category.type == 1) {
                        return sum + current.amount;
                      } else {
                        return sum - current.amount;
                      }
                    },
                    0
                  )}
                </div>
              </div>

              {transactionObj[transaction].map((data: any) => {
                return (
                  <TransactionCardList key={transaction._id} data={data} />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}
