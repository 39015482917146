import WalletListCard from "components/ui/WalletListCard";
import { useApplication } from "contexts/application/application.context"
import { GrClose } from "react-icons/gr";

type Props = {
  onClick?: any;
  onSelectedData?:Function
};

export default function WalletSelectionModal({ onClick,onSelectedData }: Props) {
  const { wallet } = useApplication();
  return (
    <div className="w-96 h-500px">
      <div>
        <div className="h-14 flex items-center border-b border-gray-300 p-6">
          <GrClose className="text-lg mr-2" onClick={onClick} />
          <h1 className="text-lg font-medium text-heading px-5">
            Select Wallet
          </h1>
        </div>
        <div className="w-full h-10 border-b border-gray-300 flex items-center px-5">
          <p className="text-xs font-light text-secondryText">
            Included in Total
          </p>
        </div>
        {wallet.map((wallet:any) => {
          return (
            <WalletListCard
              key={wallet._id}
              wallet={wallet}
              image="/assets/images/wallet.png"
              handleClick={onClick}
              onSelectedData={onSelectedData}
            />
          );
        })}
      </div>
    </div>
  );
}
