type Props = {
  title?: string;
  children?: any;
  onClick?: any;
  className?: string;
  error?: boolean;
};

export default function InputCard({
  title,
  children,
  className = "w-52",
  onClick,
  error = false,
}: Props) {
  return (
    <div
      className={`min-h-[65px] border ${
        error ? " border-red-500" : " border-gray-300"
      } hover:border-gray-500 rounded-md px-2 py-1 cursor-pointer ${className} `}
      onClick={onClick}
    >
      {" "}
      <p className="text-xs font-normal text-gray-600 mb-2">{title}</p>
      {children}
    </div>
  );
}
