import React, { FC } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "components/ui/buttton";
import { Link, useNavigate } from "react-router-dom";
import SocialMediaGroup from "components/ui/SocialMediaGroup";
import { useLoginMutation } from "framework/basic-rest/auth/user-login";
import { toast } from "react-toastify";
import { UserLoginFormValidation } from "validation-schema/user-yup-schema";

interface IProps {}

export const LoginPage: FC<IProps> = (props) => {
  const navigate = useNavigate();

  const loginMutation = useLoginMutation();

  const onsubmit = async (values: any, setErrors: any) => {
    const id = toast.loading("Please Wait");

    try {
      // const response: any =
      await loginMutation.mutateAsync({
        username: values.username,
        password: values.password,
      });

      // if (response?.ok) {
      //   toast.update(id, {
      //     render: "Login Success",
      //     type: "success",
      //     isLoading: false,
      //     autoClose: 1000,
      //   });
      //   navigate("/app");
      // } else {
      //   toast.update(id, {
      //     render: "Invalid username and password",
      //     type: "error",
      //     isLoading: false,
      //     autoClose: 1000,
      //   });
      // }

      toast.update(id, {
        render: "Login Success",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigate("/app");
    } catch (error: any) {
      if (error?.response) {
        if (error?.response.status == 422) {
          setErrors(error.response.data.data);
          toast.update(id, {
            render: "something went wrong! please try again",
            type: "error",
            isLoading: false,
            autoClose: 500,
          });
        }
      } else {
        toast.update(id, {
          render: "something went wrong! please try again",
          type: "error",
          isLoading: false,
          autoClose: 500,
        });
      }
    }
  };

  return (
    <>
      <section className="w-screen h-screen flex justify-center items-center  bg-login-bg bg-top">
        <div className="w-[700px] h-auto bg-white rounded-xl px-6 py-3 shadow-2xl">
          <div className="flex justify-center items-center">
            {" "}
            <h1 className="text-center text-3xl font-semibold text-registration-header my-5">
              Log In
            </h1>
          </div>
          <div className="md:flex items-start w-full px-2 py-4">
            <div className="w-full md:w-1/2 px-4 md:px-7 py-2 ">
              <SocialMediaGroup />
            </div>
            <div className="w-full md:w-1/2 px-4 md:px-7 py-2 md:border-l-2 border-gray-300">
              <div className="my-3 px-1">
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                    remeber_me: false,
                  }}
                  onSubmit={onsubmit}
                  validationSchema={UserLoginFormValidation}
                >
                  {({
                    values,
                    setFieldValue,
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {/* <!-- Email input --> */}
                      <div className="mb-6">
                        <Field
                          name="username"
                          type="username"
                          placeholder={"username"}
                          className={`h-12 w-full bg-input text-sm font-normal rounded-lg hover:outline hover:outline-primary focus:outline-primary focus:bg-white outline-offset-0 p-2 outline-none`}
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-xs text-red-500 pt-1"
                        />
                      </div>
                      <div className="mb-6">
                        <Field
                          name="password"
                          type={"password"}
                          placeholder={"Password"}
                          autoComplete="off"
                          className={`h-12 w-full bg-input text-sm font-normal rounded-lg hover:outline hover:outline-primary outline-offset-0 focus:outline-primary focus:bg-white p-2 outline-none`}
                        ></Field>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-xs text-red-500 pt-1"
                        />
                      </div>
                      <div className="float-right mb-6">
                        <Link to="/forgot-password">
                          <a className="text-sm font-medium text-primary hover:text-secondry focus:text-secondry active:text-secondry duration-200 transition ease-in-out">
                            Forgot password?
                          </a>
                        </Link>
                      </div>

                      <Button
                        className="w-full mb-2"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Log In
                      </Button>

                      <p className="text-sm font-semibold mt-2 pt-1 mb-0 tracking-wide text-center my-4">
                        <span>
                          {" "}
                          <a>Don’t you have Account?</a>
                        </span>
                        <Link to={"/register"}>
                          <a className="text-base font-medium text-primary hover:text-secondry hover:underline px-1">
                            Register
                          </a>
                        </Link>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   // ...
//   const { req, res } = context;

//   const session = await getSession({ req: req });

//   if (session?.user) {
//     res.writeHead(302, {
//       Location: "/",
//     });
//     res.end();
//     return {
//       props: {},
//     };
//   }

//   return {
//     props: {},
//   };
// };
