import React from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";

type Props = {
    title?:string
};

export default function DefaultInput({title}: Props) {
  return (
    <>
      <div className="flex justify-between items-center h-6 w-full">
        <div className="flex items-center">
          <AiFillQuestionCircle className="text-xl text-gray-400 " />
          <span className="text-xs font-normal text-gray-500 pl-2">
           {title}
          </span>
        </div>
        <BsChevronRight className="text-base" />
      </div>
    </>
  );
}
