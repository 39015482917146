import Modal from "components/common/modal/modal";
import WalletSelectionModal from "components/Transaction/WalletSelectionModal";
import Button from "components/ui/buttton";
import InputCard from "components/ui/InputCard";
import { useUI } from "contexts/ui.context";
import { Form, Formik } from "formik";
import { useState } from "react";
import { GrClose } from "react-icons/gr";

type Props = {};

export default function AdjustBalance({}: Props) {
  const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
  const { closeModal } = useUI();
  return (
    <div className="w-96 h-96">
      <div className="w-full h-14 flex justify-between items-center px-5  border-b border-gray-300">
        <h1 className="text-lg text-black font-medium">Adjust Balance</h1>
        <GrClose
          className="text-lg font-medium text-heading"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <Formik
        initialValues={{
          wallet: {},
          current_balence: "",
          report_agree: "",
        }}
        onSubmit={async (values: any) => {}}
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <div className="w-full px-5 py-3">
              <InputCard
                title="Wallet"
                className="w-full mb-3"
                onClick={() => {
                  setShowWalletModal(!showWalletModal);
                }}
              ></InputCard>
              <InputCard
                title="Enter current balance of this wallet"
                className="w-full"
              >
                <input
                  type="text"
                  name="current_balance"
                  className="w-full h-full outline-none text-sm font-medium text-black"
                  placeholder="0"
                />
              </InputCard>
            </div>

            <div className="px-5 py-2">
              <div className="flex items-start ">
                <div className="flex items-center h-5">
                  <input
                    name="report_agree"
                    id="agree"
                    type="checkbox"
                    className="form-checkbox w-4 h-4 mt-2 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
                  />
                </div>

                <div className="ml-3 ">
                  <label
                    htmlFor="agree"
                    className=" text-xs font-medium text-gray-800"
                  >
                    Exclude from report
                  </label>
                  <div className=" text-2xs font-normal text-secondryText">
                    Ignore this wallet and its balance in the 'Total' mode
                  </div>
                </div>
              </div>
            </div>
            <div className="float-right mt-7 p-3">
              <Button
                variant="smoke"
                className="mr-4"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </Button>
              <Button>DONE</Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={showWalletModal}
        onClose={() => setShowWalletModal(!showWalletModal)}
        useOutsideClick={false}
      >
        <WalletSelectionModal
          onClick={() => {
            setShowWalletModal(!showWalletModal);
          }}
        />
      </Modal>
    </div>
  );
}
