import Modal from "components/common/modal/modal";
import WalletSelectionModal from "components/Transaction/WalletSelectionModal";
import Button from "components/ui/buttton";
import Image from "components/ui/Image";
import InputCard from "components/ui/InputCard";
import WalletSelectedCard from "components/ui/WalletSelectedCard";
import IconSelectModal from "components/wallet/IconSelectModal";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAddCategoryMutation } from "framework/basic-rest/category/add-category";
import { useCallback, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { CategoryValidation } from "validation-schema/category-yup-schema";

type Props = {};

export default function AddCategory({}: Props) {
  const [showWalletModal, setshowWalletModal] = useState<boolean>(false);

  const { closeModal, modalData } = useUI();
  const { wallet, initialWallet, icons } = useApplication();
  const [selectedWallet, setSelectedWallet] = useState<any>(initialWallet);
  const [showIconModal, setShowIconModal] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<any>();

  const { mutateAsync } = useAddCategoryMutation();

  const onsubmit = async (values: any, setErrors: any) => {
    const final_value = {
      name: values.name,
      type: values.type,
      icon_id: values.icon.icon_name,
      wallet_id: values.wallet._id,
    };

    const id = toast.loading("Please Wait");
    try {
      await mutateAsync({
        wallet_id: values.wallet._id,
        payload: final_value,
      });

      toast.update(id, {
        render: "Category Added SuccessFully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      if (typeof modalData.categoryRefetch == "function") {
        modalData.categoryRefetch();
      }
      closeModal();
    } catch (error: any) {
      if (error?.response) {
        if (error?.response.status == 422) {
          setErrors(error.response.data.data);
          toast.update(id, {
            render: "something went wrong! please try again",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }
      } else {
        toast.update(id, {
          render: "something went wrong! please try again",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  const onSelectedData = useCallback(
    (values: any) => {
      setSelectedWallet(values);
    },
    [selectedWallet]
  );

  const onSelectedIcon = useCallback(
    (values: any) => {
      setSelectedIcon(values);
    },
    [selectedIcon]
  );
  return (
    <div className="w-96 min-h-max">
      <div className="w-full h-14 flex justify-between items-center px-5  border-b border-gray-300">
        <h1 className="text-lg text-black font-medium">Add Category</h1>
        <GrClose
          className="text-lg font-medium text-heading"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <Formik
        initialValues={{
          icon: selectedIcon,
          name: "",
          type: "",
          wallet: selectedWallet,
        }}
        onSubmit={onsubmit}
        validationSchema={CategoryValidation}
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <div className="flex items-center justify-between py-4 px-3">
              <InputCard
                className="w-24 "
                error={errors.icon ? true : false}
                onClick={() => {
                  setShowIconModal(!showIconModal);
                }}
              >
                {values.icon ? (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <Image path={values.icon?.icon_name} />
                    <BsChevronDown className="text-base" />
                  </div>
                ) : (
                  <div className="w-full h-full flex items-center justify-between p-1">
                    <div className="w-10 h-10 bg-slate-100 rounded-full flex items-center justify-center">
                      <img
                        src="/assets/images/wallet.png"
                        className="w-6 h-6 "
                      />
                    </div>
                    <BsChevronDown className="text-base" />
                  </div>
                )}
              </InputCard>
              <InputCard
                className="w-64"
                title="Category Name"
                error={errors.name ? true : false}
              >
                <Field
                  type="text"
                  name="name"
                  autoComplete="off"
                  className="w-full h-full text-xs font-normal outline-none"
                  placeholder="category name"
                />
              </InputCard>
            </div>

            <div className="flex items-center px-6">
              <div className="mr-2">
                <input
                  type="radio"
                  id="income"
                  name="type"
                  value={1}
                  className="cursor-pointer"
                  onChange={() => {
                    setFieldValue("type", 1);
                  }}
                />{" "}
                <label
                  htmlFor="income"
                  className="text-sm font-medium text-black px-1 cursor-pointer"
                >
                  Income
                </label>
              </div>
              <div className="ml-2">
                <input
                  type="radio"
                  id="expense"
                  name="type"
                  value={2}
                  className="cursor-pointer"
                  onChange={() => {
                    setFieldValue("type", 2);
                  }}
                />{" "}
                <label
                  htmlFor="expense"
                  className="text-sm font-medium text-black px-1 cursor-pointer"
                >
                  Expense
                </label>
              </div>
            </div>

            <ErrorMessage
              name="type"
              component="div"
              className="text-xs text-red-500 py-1 px-5"
            />

            <div className="px-5 py-4">
              <InputCard
                title="Wallet"
                className="w-full"
                error={errors.wallet ? true : false}
                onClick={() => {
                  setshowWalletModal(!showWalletModal);
                }}
              >
                {selectedWallet ? (
                  <WalletSelectedCard wallet={selectedWallet} />
                ) : (
                  <WalletSelectedCard wallet={wallet[0]} />
                )}
              </InputCard>
            </div>
            <div className="float-right mt-4 px-5 py-3">
              <Button
                type="button"
                variant="smoke"
                className="mr-4"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </Button>
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </div>

            {/* icons Modal */}

            {showIconModal ? (
              <Modal
                open={showIconModal}
                onClose={() => setShowIconModal(!showIconModal)}
                useOutsideClick={false}
              >
                <IconSelectModal
                  data={icons}
                  onSelectedIcon={(values: any) => {
                    setFieldValue("icon", values);
                  }}
                  onClick={() => {
                    setShowIconModal(!showIconModal);
                  }}
                />
              </Modal>
            ) : null}
          </Form>
        )}
      </Formik>
      {showWalletModal ? (
        <Modal
          open={showWalletModal}
          onClose={() => setshowWalletModal(!showWalletModal)}
          useOutsideClick={false}
        >
          <WalletSelectionModal
            onSelectedData={onSelectedData}
            onClick={() => {
              setshowWalletModal(!showWalletModal);
            }}
          />
        </Modal>
      ) : null}
    </div>
  );
}
