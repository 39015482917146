import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axios from "axios";
import http from "../utils/http";
import { API_ENDPOINTS } from "framework/basic-rest/utils/api-endpoints";


export interface SignUpInputType {
  name?:String
  email:String
  password: String;

}
async function signup(input: SignUpInputType) {
  return http.post(API_ENDPOINTS.NEW_REGISTRATION, input);
}

export const useSignUpMutation = () => {
  return useMutation((input: SignUpInputType) => signup(input), {});
};
