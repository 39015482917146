import React, { ReactNode } from "react";
import { ApplicationProvider } from "./application/application.context";
export interface State {
  isAuthorized: boolean;
  displaySidebar: boolean;
  displaySidebarMobile: boolean;
  displayFilter: boolean;
  displayModal: boolean;
  modalView: string;
  modalData: any;
  drawerView: string | null;
  toastText: string;
  user: object | null;
  isFetching?: boolean;
}

const initialState = {
  isAuthorized: false,
  displaySidebar: false,
  displaySidebarMobile: false,
  displayFilter: false,
  displayModal: false,
  modalView: "LOGIN_VIEW",
  drawerView: null,
  modalData: null,
  toastText: "",
  user: null,
  isFetching: false,
};

type MODAL_VIEWS = "LOGIN_VIEW" | "PRODUCT_VIEW";
type DRAWER_VIEWS = "MOBILE_MENU" | "MOBILE_SETTING";
type ToastText = string;

type Action =
  | {
      type: "SET_AUTHORIZED";
      user: any;
    }
  | {
      type: "SET_UNAUTHORIZED";
    }
  | {
      type: "OPEN_SIDEBAR";
    }
  | {
      type: "OPEN_SIDEBAR_MOBILE";
    }
  | {
      type: "CLOSE_SIDEBAR_MOBILE";
    }
  | {
      type: "SET_FETCHING";
      status: boolean;
    }
  | {
      type: "CLOSE_SIDEBAR";
    }
  | {
      type: "SET_TOAST_TEXT";
      text: ToastText;
    }
  | {
      type: "OPEN_FILTER";
    }
  | {
      type: "CLOSE_FILTER";
    }
  | {
      type: "OPEN_MODAL";
    }
  | {
      type: "CLOSE_MODAL";
    }
  | {
      type: "SET_MODAL_VIEW";
      view: MODAL_VIEWS;
    }
  | {
      type: "SET_DRAWER_VIEW";
      view: DRAWER_VIEWS;
    }
  | {
      type: "SET_MODAL_DATA";
      data: any;
    }
  | {
      type: "SET_USER_AVATAR";
      value: string;
    };

export const UIContext = React.createContext<State | any>(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "SET_AUTHORIZED": {
      return {
        ...state,
        isAuthorized: true,
        user: action.user,
      };
    }
    case "SET_UNAUTHORIZED": {
      return {
        ...state,
        isAuthorized: false,
        user: null,
      };
    }
    case "SET_FETCHING": {
      return {
        ...state,
        isFetching: action.status,
      };
    }
    case "OPEN_SIDEBAR": {
      return {
        ...state,
        displaySidebar: true,
      };
    }
    case "CLOSE_SIDEBAR": {
      return {
        ...state,
        displaySidebar: false,
        drawerView: null,
      };
    }
    case "OPEN_SIDEBAR_MOBILE": {
      return {
        ...state,
        displaySidebarMobile: true,
      };
    }
    case "CLOSE_SIDEBAR_MOBILE": {
      return {
        ...state,
        displaySidebarMobile: false,
        drawerView: null,
      };
    }
    case "OPEN_FILTER": {
      return {
        ...state,
        displayFilter: true,
      };
    }
    case "CLOSE_FILTER": {
      return {
        ...state,
        displayFilter: false,
      };
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      };
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        displayModal: false,
      };
    }
    case "SET_MODAL_VIEW": {
      return {
        ...state,
        modalView: action.view,
      };
    }
    case "SET_DRAWER_VIEW": {
      return {
        ...state,
        drawerView: action.view,
      };
    }
    case "SET_MODAL_DATA": {
      return {
        ...state,
        modalData: action.data,
      };
    }
    case "SET_TOAST_TEXT": {
      return {
        ...state,
        toastText: action.text,
      };
    }
    case "SET_USER_AVATAR": {
      return {
        ...state,
        userAvatar: action.value,
      };
    }
  }
}

export const UIProvider: React.FC<any> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);
  const authorize = (user: any) =>
    dispatch({ type: "SET_AUTHORIZED", user: user });
  const unauthorize = () => dispatch({ type: "SET_UNAUTHORIZED" });
  const openSidebar = () => dispatch({ type: "OPEN_SIDEBAR" });
  const closeSidebar = () => dispatch({ type: "CLOSE_SIDEBAR" });
  const openSidebarMobile = () => dispatch({ type: "OPEN_SIDEBAR_MOBILE" });
  const closeSidebarMobile = () => dispatch({ type: "CLOSE_SIDEBAR_MOBILE" });

  const toggleSidebar = () =>
    state.displaySidebar
      ? dispatch({ type: "CLOSE_SIDEBAR" })
      : dispatch({ type: "OPEN_SIDEBAR" });
  const toggleSidebarMobile = () =>
    state.displaySidebarMobile
      ? dispatch({ type: "CLOSE_SIDEBAR_MOBILE" })
      : dispatch({ type: "OPEN_SIDEBAR_MOBILE" });

  const openFilter = () => dispatch({ type: "OPEN_FILTER" });
  const closeFilter = () => dispatch({ type: "CLOSE_FILTER" });

  const openModal = () => dispatch({ type: "OPEN_MODAL" });
  const closeModal = () => dispatch({ type: "CLOSE_MODAL" });

  const setUserAvatar = (_value: string) =>
    dispatch({ type: "SET_USER_AVATAR", value: _value });

  const setModalView = (view: MODAL_VIEWS) =>
    dispatch({ type: "SET_MODAL_VIEW", view });
  const setDrawerView = (view: DRAWER_VIEWS) =>
    dispatch({ type: "SET_DRAWER_VIEW", view });
  const setModalData = (data: any) =>
    dispatch({ type: "SET_MODAL_DATA", data });

  const setLoadingState = (data: boolean) =>
    dispatch({ type: "SET_FETCHING", status: data });

  const value = React.useMemo(
    () => ({
      ...state,
      authorize,
      unauthorize,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      openFilter,
      closeFilter,
      openModal,
      closeModal,
      setModalView,
      setDrawerView,
      setUserAvatar,
      setModalData,
      setLoadingState,
      toggleSidebarMobile,
      openSidebarMobile,
      closeSidebarMobile,
    }),
    [state]
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI: any = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};
interface BaseLayoutProps {
  children?: ReactNode;
}

export const ManagedUIContext: React.FC<BaseLayoutProps> = ({ children }) => {
  return (
    <ApplicationProvider>
      <UIProvider>{children}</UIProvider>
    </ApplicationProvider>
  );
};
