import { BsChevronRight } from "react-icons/bs";

type Props = {
  currency?: any;
};

export default function CurrencyInputCard({ currency }: Props) {
  return (
    <div className="flex justify-between items-center h-6 w-full">
      <div className="flex items-center">
        <img src={`/assets/images/india.png`} className="w-5 h-5 " />
        <span className="text-xs font-normal text-gray-500 pl-2">
          {currency?.name}
        </span>
      </div>
      <BsChevronRight className="text-base" />
    </div>
  );
}
