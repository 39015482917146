import WalletListCard from "components/ui/WalletListCard";

type Props = {
  onHandleData?: Function;
  wallets?: any;
};

export default function WalletList({ onHandleData, wallets }: Props) {
  return (
    <div className=" w-full md:w-3/6 lg:w-3/6 min-h-[100px] bg-white rounded-sm mr-2 sticky shadow-lg">
      <div className="text-2xs font-normal text-appText p-4 border-b border-gray-300">
        Included in Total
      </div>
      <div>
        {wallets?.map((wallet: any) => {
          return (
            <WalletListCard
              key={wallet._id}
              wallet={wallet}
              handleClick={() => {
                if (typeof onHandleData == "function") {
                  onHandleData(wallet);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
