import Image from "components/ui/Image";
import { useApplication } from "contexts/application/application.context";
import { useUI } from "contexts/ui.context";
import { GrClose } from "react-icons/gr";

type Props = {
  data?: any;
  onClose?: Function;
};

export default function CateGoryDetailes({ data, onClose }: Props) {
  const { openModal, setModalView, setModalData } = useUI();

  const { wallet } = useApplication();

  const walletName = wallet?.find((wallet: any) => {
    if (wallet.id == data.wallet_id) {
      return wallet;
    }
  });

  return (
    <div className=" w-full md:w-3/6 lg:w-4/6 min-h-max bg-white rounded-sm shadow-lg ml-3 sticky top-5 overflow-y-auto bg-scroll">
      <div className="h-14 flex items-center justify-between border-b border-gray-300 px-5">
        <div className="flex items-center">
          <GrClose
            className="text-base lg:text-lg mr-1 md:mr-2 cursor-pointer"
            onClick={() => {
              if (typeof onClose == "function") {
                onClose();
              }
            }}
          />
          <h1 className="text-[14px] md:text-base lg:text-lg font-medium text-heading px-5">
            Category details
          </h1>
        </div>
        <div>
          {data.is_editable ? (
            <>
              {" "}
              <span className=" text-2xs md:text-xs lg:text-sm text-red-600 font-medium mx-2 lg:mx-4 cursor-pointer">
                DELETE
              </span>
              <span
                className="text-2xs md:text-xs lg:text-sm text-primary font-medium mx-2 lg:mx-4 cursor-pointer"
                onClick={() => {
                  setModalData({
                    data: data,
                  });
                  setModalView("EDIT_CATEGORY");
                  openModal();
                }}
              >
                EDIT
              </span>
            </>
          ) : null}
        </div>
      </div>
      <div className=" px-5 md:px-10 py-5 flex items-start ">
        <Image path={data.icon_id} size="lg" />

        <div className="pl-4">
          <h3 className="text-lg font-medium text-heading">{data.name}</h3>
          <p className="text-xs font-normal text-secondryText my-1">
            {walletName.name}
          </p>
          {data.type == 2 ? (
            <span className="text-2xs font-normal text-white bg-red-500 px-2 py-0.5 rounded-full">
              Expense
            </span>
          ) : (
            <span className="text-2xs font-normal text-white bg-blue-400 px-2 py-0.5 rounded-full">
              Income
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
