import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axios from "axios";
import http from "../utils/http";
import { API_ENDPOINTS } from "framework/basic-rest/utils/api-endpoints";

export interface InputType {
  wallet_id: string;
  transaction_id: string;
}
async function deleteTransaction(input: InputType) {
  return http.delete(
    `${API_ENDPOINTS.TRANSACTION}/${input.wallet_id}/${input.transaction_id}`
  );
}

export const useDeleteTransactionMutation = () => {
  return useMutation((input: InputType) => deleteTransaction(input), {});
};
