import React from "react";

type Props = {
  users?: any;
};

export default function UsersList({ users }: Props) {
  return (
    <div className="">
      {users?.map((user: any) => {
        // const userName = user.email.splite("@", 1);
        return (
          <div className="flex items-center my-4" key={user?.id}>
            <div className="text-lg lg:text-2xl font-medium w-10 h-10 rounded-full bg-blue-500 text-white flex items-center justify-center p-2">
              {user?.info?.name?.toUpperCase().charAt(0)}
            </div>
            <div className="px-4">
              <p className="text-2xs lg:text-xs font-medium text-black ">
                {user?.info?.name}{" "}
                <span className="text-2xs lg:text-xs  bg-orange-400 px-1 py-0.5 text-white rounded font-normal mx-1">
                  {user?.role}
                </span>
              </p>

              <p className="text-3xs lg:text-2xs font-normal text-secondryText py-1">
                {user?.info?.email}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
