import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axios from "axios";
import http from "../utils/http";
import { API_ENDPOINTS } from "framework/basic-rest/utils/api-endpoints";

export interface TransactionInputType {
  wallet_id?: any;
  payload?: {
    name?: string;
    type?: number;
    icon_id?: string;
  };
}
async function addCategory(input: TransactionInputType) {
  return http.post(
    `${API_ENDPOINTS.CATEGORY_LIST}/${input.wallet_id}`,
    input.payload
  );
}

export const useAddCategoryMutation = () => {
  return useMutation((input: TransactionInputType) => addCategory(input), {});
};
