import { useApplication } from "contexts/application/application.context";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import http from "../utils/http";

async function fetchAllWallets() {
  return http.get(API_ENDPOINTS.WALLET_LIST);
}

export const useWalletsQuery = () => {
  const { setWallet, setInitialWallet } = useApplication();
  return useQuery([API_ENDPOINTS.WALLET_LIST], fetchAllWallets, {
    onSuccess(data: any) {
      if (data.data) {
        if (data.data.length > 0) {
          setInitialWallet(data.data[0]);
          setWallet(data.data);
        } else {
          setInitialWallet(null);
          setWallet([]);
        }
      }

      // setWallet && setWallet(data);
    },
  });
};
