import { Listbox } from "@headlessui/react";
import { useApplication } from "contexts/application/application.context";
import { useEffect, useState } from "react";
import Image from "./Image";

type Props = {
  children?: any;
  selectedOption?: any;
  onClick?: any;
  wallet?: any;
};

export default function WalletDropDown({
  children,
  onClick,
  selectedOption,
  wallet,
}: Props) {
  const [selectedValue, setSelectedValue] = useState<any>(
    selectedOption ? selectedOption : null
  );

  useEffect(() => {
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [selectedOption]);

  return (
    <Listbox value={selectedValue} onChange={setSelectedValue}>
      <div className={`relative`}>
        <Listbox.Button
          className={`rounded-md flex justify-between items-center`}
        >
          {children}
        </Listbox.Button>
        <Listbox.Options
          className={`absolute bg-white origin-bottom overflow-auto rounded-lg text-base shadow-lg  focus:outline-none overflow-x-hidden bg-scroll w-60 min-h-[300px] top-14 right-0 z-50 `}
        >
          <div className="text-2xs font-light text-heading p-2 border-b border-gray-300">
            Include in Total
          </div>
          {wallet?.map((wallet: any) => {
            return (
              <Listbox.Option
                key={wallet._id}
                value={wallet}
                className={({ active, selected }) =>
                  `w-full h-16 py-2 px-2 rounded-sm border-b border-gray-300 hover:bg-green-100 ${
                    active ? "text-sm" : "text-sm"
                  } ${selected ? "bg-green-100" : "bg-white"}`
                }
                onClick={() => {
                  if (typeof onClick == "function") {
                    onClick(wallet);
                  }
                }}
              >
                <div className="w-full h-full flex items-center">
                  <Image path={wallet.icon_name} />
                  <div className=" h-full px-3 flex">
                    <div>
                      <p className="text-xs font-medium text-heading">
                        {wallet.name}
                      </p>
                      <p className="text-xs font-normal text-secondryText">
                        {wallet.amount ? wallet.amount : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
