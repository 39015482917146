import cn from "classnames";
import React, { forwardRef, ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: "flat" | "slim" | "smoke" | "cart" | "outline";
  active?: boolean;
  type?: "submit" | "reset" | "button";
  loading?: boolean;
  disabled?: boolean;
  onClick?: any;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    variant = "flat",
    children,
    active,
    loading = false,
    disabled = false,
    onClick,
    ...rest
  } = props;

  const rootClassName = cn(
    `text-[14px] md:text-xs leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-medium text-center justify-center  rounded-lg placeholder-white focus-visible:outline-none focus:outline-none`,
    {
      "bg-primary hover:bg-secondry text-white px-3 md:px-4 lg:px-4 py-2 md:py-3 lg:py-3 hover:text-white hover:bg-gray-600 hover:shadow-cart border-0 border-transparent":
        variant === "flat",
      "h-8 md:h-10 px-5 bg-gradient-to-r from-primaryStart to-primaryEnd text-white py-2 transform-none normal-case hover:text-white hover:bg-primaryHover hover:shadow-cart border-0 border-transparent":
        variant === "slim",
      "px-3 md:px-4 lg:px-4 py-2 md:py-3 lg:py-3  bg-gray-200 hover:bg-green-100 text-primary  transform-none normal-case hover:bg-bg-gray-200 border-0 border-transparent":
        variant === "smoke",
      "h-6 md:h-10 px-5 bg-white border-2 border-primary text-primary py-5 transform-none normal-case hover:text-primary":
        variant === "outline",
      "cursor-not-allowed": loading,
      "cursor-not-allowed hover:cursor-not-allowed": disabled,
    },
    className
  );

  return (
    <button
      aria-pressed={active}
      data-variant={variant}
      ref={ref}
      className={rootClassName}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
      {loading && (
        <svg
          className="animate-spin -me-1 ms-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
    </button>
  );
});

export default Button;
