import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axios from "axios";
import http from "../utils/http";
import { API_ENDPOINTS } from "framework/basic-rest/utils/api-endpoints";

export interface InputType {
  wallet_id: string;
}
async function deleteWallet(input: InputType) {
  return http.delete(`${API_ENDPOINTS.WALLET_LIST}/${input.wallet_id}`);
}

export const useDeleteWalletMutation = () => {
  return useMutation((input: InputType) => deleteWallet(input), {});
};
