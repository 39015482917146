import EventListCard from "components/ui/EventCardList";
import WalletListCard from "components/ui/WalletListCard";
import React from "react";
import { GrClose } from "react-icons/gr";

type Props = {
  onClick?: any;
};

const eventData = [
  {
    id: 1,
    place: "salem",
  },
  {
    id: 2,
    place: "Chennai",
  },
];

export default function EventSelectionModal({ onClick }: Props) {
  return (
    <div className="w-96 h-500px">
      <div>
        <div className="h-14 flex items-center border-b border-gray-300 p-6">
          <GrClose className="text-lg mr-2" onClick={onClick} />
          <h1 className="text-lg font-medium text-heading px-5">
            Select Event
          </h1>
        </div>
        {/* <div className="w-full h-10 border-b border-gray-300 flex items-center px-5">
          <p className="text-xs font-light text-secondryText">
            Included in Total
          </p>
        </div> */}
        {eventData.map((event: any, index: number) => {
          return (
            <EventListCard
              name={event.place}
              image="/assets/images/event.png"
              handleClick={onClick}
            />
          );
        })}
      </div>
    </div>
  );
}
