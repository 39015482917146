import React from "react";
import Image from "./Image";

type Props = {
  wallet?: any;
  name?: string;
  amount?: string;
  image?: string;
  handleClick?: any;
  onSelectedData?: Function;
};

export default function WalletListCard({
  wallet,
  name,
  amount,
  handleClick,
  onSelectedData,
}: Props) {
  return (
    <div
      className="w-full min-h-[60px] bg-white hover:bg-green-100 border-b border-gray-300 flex items-center px-5 py-3 cursor-pointer"
      onClick={() => {
        if (typeof handleClick == "function") {
          handleClick(wallet);
        }
        if (typeof onSelectedData == "function") {
          onSelectedData(wallet);
        }
      }}
    >
      <Image path={wallet.icon_name} />
      <div className="px-4">
        <p className="text-xs font-medium text-black py-1">
          {wallet ? wallet.name : name}
        </p>
        <p className="text-2xs font-light text-secondryText">
          {wallet ? wallet.amount : amount}
        </p>
      </div>
    </div>
  );
}
