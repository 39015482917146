import ContactListCard from "components/ui/ContactListCard";
import SearchBar from "components/ui/SearchBar";
import WalletListCard from "components/ui/WalletListCard";
import React from "react";
import { GrClose } from "react-icons/gr";

type Props = {
  onClick?: any;
};

export default function ContactSelectionModal({ onClick }: Props) {
  return (
    <div className="w-96 h-500px">
      <div>
        <div className="h-14 flex items-center border-b border-gray-300 p-6">
          <GrClose className="text-lg mr-2" onClick={onClick} />
          <h1 className="text-lg font-medium text-heading px-5">With</h1>
        </div>
        <div className="my-3 px-3">
          <SearchBar />
        </div>
        <ContactListCard
          name="sathiyamoorhti"
          number="7010043514"
          image="/assets/images/contact.png"
          handleClick={onClick}
        />
      </div>
    </div>
  );
}
