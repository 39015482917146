import { useApplication } from "contexts/application/application.context";
import { useTransactionQuery } from "framework/basic-rest/transaction/get-transaction";
import moment from "moment";
import { transactionContext } from "pages/app/transaction/TransactionsPage";
import { useContext, useEffect } from "react";
import TotalFlow from "./TotalFlow";
import TransactionList from "./TransactionList";

type Props = { month?: string };

const TransactionBody = ({ month }: Props) => {
  const wallet_id = useContext(transactionContext);

  const { initialWallet } = useApplication();

  const { data, refetch, isLoading } = useTransactionQuery({
    wallet_id: initialWallet?._id,
    start_date: moment(month).clone().startOf("month").format("YYYY-MM-DD"),
    end_date: moment(month).clone().endOf("month").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    refetch();
  }, [month]);

  if (isLoading) {
    return (
      <div className="w-full h-60 bg-gray-100 opacity-100 flex justify-center items-center">
        <svg
          className="animate-spin  h-10 w-10 text-primary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-50"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    );
  }

  if (data.length == 0) {
    return (
      <div className="w-full h-60 bg-gray-200 opacity-30 flex justify-center items-center flex-col">
        <img
          src="/assets/images/no_data.png"
          alt="no Data"
          className="w-12 h-12"
        />
        <h3 className="text-2xl font-medium text-secondryText my-3">
          No Transactions
        </h3>
      </div>
    );
  }

  return (
    <div>
      <TotalFlow data={data} />
      <TransactionList data={data} />
    </div>
  );
};

export default TransactionBody;
