import Modal from "components/common/modal/modal";
import Button from "components/ui/buttton";
import InputCard from "components/ui/InputCard";
import CurrencyInputCard from "components/wallet/CurrencyInputCard";
import CurrencySelectionModal from "components/wallet/CurrencySelectionModal";
import IconSelectModal from "components/wallet/IconSelectModal";
import { useApplication } from "contexts/application/application.context";
import { Field, Formik } from "formik";
import { useAddWalletMutation } from "framework/basic-rest/wallet/add-wallet";
import { useWalletsQuery } from "framework/basic-rest/wallet/get-wallets";
import { FC, useCallback, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Form, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {}

/**
 * @author
 * @function @AddWalletPage
 **/

export const AddWalletPage: FC<IProps> = (props) => {
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);
  const [showIconModal, setShowIconModal] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<any>();
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const { icons } = useApplication();

  const { mutateAsync } = useAddWalletMutation();

  const navigate = useNavigate();
  const onSelectedIcon = useCallback(
    (values: any) => {
      setSelectedIcon(values);
    },
    [selectedIcon]
  );
  const onSelectedCurrency = useCallback(
    (values: any) => {
      setSelectedCurrency(values);
    },
    [selectedCurrency]
  );

  

  const onsubmit = async (values: any, setErrors: any) => {
    const final_value = {
      currency_code: selectedCurrency.code,
      name: values.name,
      type: 1,
      icon_name: selectedIcon.icon_name,
    };

    const id = toast.loading("Please Wait");

    try {
      await mutateAsync(final_value);
      // {
      //   name: values.wallet_name,
      //   currency_id: values.currency,
      //   icon: values.icon,
      //   balance: values.initial_amount,
      // }
      toast.update(id, {
        render: "Wallet Added SuccessFully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    } catch (error: any) {
      if (error?.response) {
        if (error?.response.status == 422) {
          setErrors(error.response.data.data);
          toast.update(id, {
            render: "something went wrong! please try again",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }
      } else {
        toast.update(id, {
          render: "something went wrong! please try again",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="w-[400px] h-96 bg-white rounded-md shadow-md">
        <div className="w-full h-14 flex justify-between items-center px-7  border-b border-gray-300">
          <h1 className="text-lg text-black font-medium">Add Wallet</h1>
          {/* <GrClose
          className="text-lg font-medium text-heading"
          onClick={() => {
            closeModal();
          }}
        /> */}
        </div>
        <Formik
          initialValues={{
            icon: {},
            name: "",
            currency: "",
            initial_amount: "",
            agree: false,
          }}
          onSubmit={onsubmit}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className="px-2">
              <div className="flex items-center justify-between py-4 px-3">
                <InputCard
                  className="w-24 "
                  onClick={() => {
                    setShowIconModal(!showIconModal);
                  }}
                >
                  {selectedIcon ? (
                    <div className="w-full h-full flex items-center justify-between p-1">
                      <div className="w-9 h-9 rounded-full flex items-center justify-center">
                        <img
                          src={`/assets/${selectedIcon?.location_id}/${selectedIcon?.icon_name}`}
                          className="w-full h-full "
                        />
                      </div>
                      <BsChevronDown className="text-base" />
                    </div>
                  ) : (
                    <div className="w-full h-full flex items-center justify-between p-1">
                      <div className="w-10 h-10 bg-slate-100 rounded-full flex items-center justify-center">
                        <img
                          src="/assets/images/wallet.png"
                          className="w-6 h-6 "
                        />
                      </div>
                      <BsChevronDown className="text-base" />
                    </div>
                  )}
                </InputCard>
                <InputCard className="w-64" title="Wallet Name">
                  <Field
                    type="text"
                    name="name"
                    className="w-full h-full text-xs font-normal outline-none"
                    placeholder="Your Wallet Name"
                    autoComplete="off"
                  />
                </InputCard>
              </div>
              <div className="flex items-center justify-between py-1 px-3">
                <InputCard
                  className="w-48 "
                  title="Currency"
                  onClick={() => {
                    setShowCurrencyModal(!showCurrencyModal);
                  }}
                >
                  {selectedCurrency ? (
                    <CurrencyInputCard currency={selectedCurrency} />
                  ) : null}
                </InputCard>
                <InputCard className="w-40" title="Initial Balance">
                  <Field
                    type="text"
                    name="initial_amount"
                    className="w-full h-full text-sm font-normal outline-none"
                    placeholder="0"
                    autoComplete="off"
                  />
                </InputCard>
              </div>
              <div className="px-4 py-2">
                <div className="flex items-start my-3">
                  <div className="flex items-center h-5">
                    <input
                      name="agree"
                      id="agree"
                      type="checkbox"
                      className="form-checkbox w-4 h-4 mt-2 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
                    />
                  </div>

                  <div className="ml-3 ">
                    <label
                      htmlFor="agree"
                      className=" text-xs font-medium text-gray-800"
                    >
                      Exclude from Total
                    </label>
                    <div className=" text-2xs font-normal text-secondryText">
                      Ignore this wallet and its balance in the 'Total' mode
                    </div>
                  </div>
                </div>
              </div>
              <div className="float-right mt-4 p-3">
                <Button
                  type="button"
                  variant="smoke"
                  className="mr-4"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  BACK
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  SAVE
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        <Modal
          open={showCurrencyModal}
          onClose={() => setShowCurrencyModal(!showCurrencyModal)}
          useOutsideClick={false}
        >
          <CurrencySelectionModal
            onSelectedCurrency={onSelectedCurrency}
            onClick={() => {
              setShowCurrencyModal(!showCurrencyModal);
            }}
          />
        </Modal>
        <Modal
          open={showIconModal}
          onClose={() => setShowIconModal(!showIconModal)}
          useOutsideClick={false}
        >
          <IconSelectModal
            data={icons}
            onSelectedIcon={onSelectedIcon}
            onClick={() => {
              setShowIconModal(!showIconModal);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AddWalletPage;
