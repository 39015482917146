import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

type Props = {};

export default function SearchBar({}: Props) {
  return (
    <div className="w-full h-9 bg-gray-100 rounded-full flex items-center">
      <AiOutlineSearch className="text-xl text-secondryText mx-3" />
      <input
        type="text"
        className="w-full h-full bg-gray-100 rounded-r-full outline-none text-sm placeholder:italic pr-2"
        placeholder="Search"
      />
    </div>
  );
}
