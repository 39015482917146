import { AppLayout } from "layout/AppLayout";
import { createBrowserRouter } from "react-router-dom";
import CategoryPage from "./app/category/CategoryPage";
import TransactionsPage from "./app/transaction/TransactionsPage";
import ProfilePage from "./app/user/ProfilePage";
import WalletPage from "./app/wallet/WalletPage";
import LoginPage from "./Login";
import AddWalletPage from "./onboard/AddWallet";
import RegisterPage from "./Register";

const MainRouters: any = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/app",
    element: <AppLayout />,
    children: [
      {
        index: true,

        element: <TransactionsPage />,
      },
      {
        path: "wallet",
        element: <WalletPage />,
      },
      {
        path: "transaction",
        element: <TransactionsPage />,
      },
      {
        path: "category",
        element: <CategoryPage />,
      },
      {
        path: "user/profile",
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/onboard/AddWallet",
    element: <AddWalletPage />,
  },
];

export default createBrowserRouter(MainRouters);
