import { GrClose } from "react-icons/gr";

type Props = {
  onClick?: any;
};

export default function LocationModal({ onClick }: Props) {
  return (
    <div className="w-96 h-500px">
      <div>
        <div className="h-14 flex items-center border-b border-gray-300 p-6">
          <GrClose className="text-lg mr-2" onClick={onClick} />
          <h1 className="text-lg font-medium text-heading px-5">
            Select Location
          </h1>
        </div>
      </div>
    </div>
  );
}
