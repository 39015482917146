import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import http from "../utils/http";
import { API_ENDPOINTS } from "framework/basic-rest/utils/api-endpoints";

export interface WalletInputType {
  name: String;
  currency_code?: String;
  icon_name?: string;
  balance?: String;
}
async function addWallet(input: WalletInputType) {
  return http.post(API_ENDPOINTS.WALLET_LIST, input);
}

export const useAddWalletMutation = () => {

  const queryClient = useQueryClient();

  return useMutation((input: WalletInputType) => addWallet(input), {
     onSuccess(data, variables, context) {

        queryClient.invalidateQueries([API_ENDPOINTS.WALLET_LIST])

     },
  });
};
