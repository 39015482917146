import { useState } from "react";
import { Tab } from "@headlessui/react";
import cn from "classnames";
import moment from "moment";
import TransactionBody from "components/Transaction/TransactionBody";

type Props = {};

export default function Tabs({}: Props) {
  const [month, setMonth] = useState<any>(new Date());
  const currentMonth = moment(new Date());

  const start = moment(month).clone().startOf("month").format("DD/MM/YYYY");
  const end = moment(month).clone().endOf("month").format("DD/MM/YYYY");

  const beforeMonth = moment(month).subtract(1, "months").format("YYYY/MM/DD");
  const beforeMonthstart = moment(beforeMonth)
    .clone()
    .startOf("month")
    .format("DD/MM/YYYY");
  const beforeMonthend = moment(beforeMonth)
    .clone()
    .endOf("month")
    .format("DD/MM/YYYY");

  const afterMonth = moment(month).add(1, "months").format("YYYY/MM/DD");
  const afterMonthstart = moment(afterMonth)
    .clone()
    .startOf("month")
    .format("DD/MM/YYYY");
  const afterMonthend = moment(afterMonth)
    .clone()
    .endOf("month")
    .format("DD/MM/YYYY");

  return (
    <>
      <div className="w-full  sm:px-0">
        <Tab.Group selectedIndex={1}>
          <Tab.List className="flex space-x-1 border-b border-gray-200 sticky top-0 z-10 bg-white">
            <Tab
              className={({ selected }: any) =>
                cn(
                  "w-full  py-3 lg:py-5 text-2xs lg:text-xs font-medium leading-5 text-black outline-none p-5",
                  selected ? "text-green-500 border-b-2 border-green-500" : ""
                )
              }
              onClick={() => {
                setMonth(
                  moment(month).subtract(1, "months").format("YYYY/MM/DD")
                );
              }}
            >
              {moment(beforeMonth).format("MM/YYYY") ==
              moment(currentMonth).subtract(1, "months").format("MM/YYYY")
                ? "LAST MONTH"
                : moment(beforeMonth).format("MM/YYYY") ==
                  moment(currentMonth).format("MM/YYYY")
                ? "This Month"
                : `${beforeMonthstart}-${beforeMonthend}`}
            </Tab>
            <Tab
              className={({ selected }: any) =>
                cn(
                  "w-full  py-3 lg:py-5 text-2xs lg:text-xs font-medium leading-5 text-black outline-none p-5",
                  selected ? "text-green-500 border-b-2 border-green-500" : ""
                )
              }
            >
              {moment(month).format("MM/YYYY") ==
              moment(currentMonth).format("MM/YYYY")
                ? "THIS MONTH"
                : moment(month).format("MM/YYYY") ==
                  moment(currentMonth).subtract(1, "months").format("MM/YYYY")
                ? "LAST MONTH"
                : moment(month).format("MM/YYYY") ==
                  moment(currentMonth).add(1, "months").format("MM/YYYY")
                ? "FUTURE"
                : `${start}-${end}`}
            </Tab>
            <Tab
              className={({ selected }: any) =>
                cn(
                  "w-full   py-3 lg:py-5 text-2xs lg:text-xs font-medium leading-5 text-black outline-none p-5",
                  selected ? "text-green-500 border-b-2 border-green-500" : ""
                )
              }
              onClick={() => {
                setMonth(moment(month).add(1, "months").format("YYYY/MM/DD"));
              }}
              disabled={
                moment(afterMonth).format("MM/YYYY") ==
                moment(currentMonth).add(2, "months").format("MM/YYYY")
              }
            >
              {moment(afterMonth).format("MM/YYYY") ==
              moment(currentMonth).add(2, "months").format("MM/YYYY")
                ? null
                : moment(afterMonth).format("MM/YYYY") ==
                  moment(currentMonth).format("MM/YYYY")
                ? "THIS MONTH"
                : moment(afterMonth).format("MM/YYYY") ==
                  moment(currentMonth).add(1, "months").format("MM/YYYY")
                ? "FUTURE"
                : moment(afterMonth).format("MM/YYYY") ==
                  moment(currentMonth).subtract(1, "months").format("MM/YYYY")
                ? `LAST MONTH`
                : `${afterMonthstart}-${afterMonthend}`}
            </Tab>
          </Tab.List>

          <Tab.Panels className="">
            <Tab.Panel className={cn("rounded-xl bg-white")}>
              <TransactionBody month={month} />
            </Tab.Panel>
          </Tab.Panels>

          <Tab.Panels className="">
            <Tab.Panel className={cn("rounded-xl bg-white")}>
              <TransactionBody month={month} />
            </Tab.Panel>
          </Tab.Panels>

          <Tab.Panels className="">
            <Tab.Panel className={cn("rounded-xl bg-white")}>
              <TransactionBody month={month} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
