import { useUI } from "contexts/ui.context";
import Modal from "components/common/modal/modal";
import { useCallback, useState } from "react";
import Flatpickr from "react-flatpickr";
import WalletSelectionModal from "components/Transaction/WalletSelectionModal";
import ContactSelectionModal from "components/Transaction/ContactSelectionModal";
import EventSelectionModal from "components/Transaction/EventSelectionModal";
import LocationModal from "components/Transaction/LocationModal";
import CategorySelectionModal from "components/Transaction/CategorySelectionModal";
import InputCard from "components/ui/InputCard";
import DefaultInput from "components/ui/DefaultInput";
import PhotoUploadCard from "components/ui/PhotoUploadCard";
import Button from "components/ui/buttton";
import { Field, Form, Formik } from "formik";
import WalletSelectedCard from "components/ui/WalletSelectedCard";
import { useCategoryQuery } from "framework/basic-rest/category/get-categorys";

function EditTransaction() {
  const { openModal, setModalView, closeModal, modalData } = useUI();
  const [showWalletModal, setshowWalletModal] = useState<boolean>(false);
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [showDate, setShowDate] = useState<boolean>(false);

  const [selectedWallet, setSelectedWallet] = useState(modalData?.wallet);
  const [selectedCategory, setSelectedCategory] = useState<any>(
    modalData?.category
  );

  const { data } = useCategoryQuery(selectedWallet?._id);

  const onSelectedData = useCallback(
    (values: any) => {
      setSelectedWallet(values);
    },
    [selectedWallet]
  );
  const onSelectedCategory = useCallback(
    (values: any) => {
      setSelectedCategory(values);
    },
    [selectedCategory]
  );

  return (
    <>
      <div className="w-[700px] lg:w-[800px]">
        <div className="w-full h-16 text-xl text-black font-medium flex items-center border-b border-gray-200 px-5">
          Edit transaction
        </div>
        <Formik
          initialValues={{
            wallet: selectedWallet,
            category: selectedCategory,
            amount: "",
            display_date: modalData.display_date,
            note: "",
            contact: {},
            remaider: "",
            location: {},
            event: {},
            photo: "",
            report_agree: false,
            ...modalData,
          }}
          onSubmit={async (values: any) => {}}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <div className="px-5 py-5">
                <div className="w-full flex items-center justify-between ">
                  <InputCard
                    title="Wallet"
                    className="w-52 lg:w-60"
                    onClick={() => {
                      setshowWalletModal(!showWalletModal);
                    }}
                  >
                    {selectedWallet ? (
                      <WalletSelectedCard wallet={selectedWallet} />
                    ) : (
                      <WalletSelectedCard wallet={selectedWallet} />
                    )}
                  </InputCard>

                  <InputCard
                    title="Category"
                    className="w-52 lg:w-60"
                    onClick={() => {
                      setShowCategoryModal(!showCategoryModal);
                    }}
                  >
                    {selectedCategory ? (
                      <WalletSelectedCard wallet={selectedCategory} />
                    ) : (
                      <DefaultInput title="Select Category" />
                    )}
                  </InputCard>

                  <InputCard title="Amount" className="w-52 lg:w-60 ">
                    <Field
                      type="text"
                      name="amount"
                      className="w-full h-full outline-none p-1"
                      placeholder="Amount"
                    />
                  </InputCard>
                </div>
                <div className="w-full flex items-center justify-between pt-3 ">
                  <InputCard title="Date" className="w-52 lg:w-60">
                    <Flatpickr
                      placeholder="DD/MM/YYYY"
                      className="w-full h-full outline-none text-sm"
                      options={{
                        defaultDate: new Date(),
                        dateFormat: "d/m/Y",
                        minDate: "today",
                        enableTime: true,
                      }}
                    />
                  </InputCard>

                  <InputCard title="Note" className="w-[66%] ">
                    <Field
                      type="text"
                      name="note"
                      className="w-full h-full outline-none p-1"
                      placeholder="Note"
                    />
                  </InputCard>
                </div>
              </div>

              <div className="px-5 py-1">
                <span className="text-primary text-sm font-normal cursor-pointer hover:underline">
                  Add More Details
                </span>
              </div>

              <div className="px-5 py-2">
                <div className="flex items-center ">
                  <div className="">
                    <InputCard
                      title="With"
                      className="w-52 lg:w-60 mb-3"
                      onClick={() => {
                        setShowContactModal(!showContactModal);
                      }}
                    >
                      <DefaultInput title="With" />
                    </InputCard>

                    <InputCard
                      title="Location"
                      className="w-52 lg:w-60 my-1"
                      onClick={() => {
                        setShowLocationModal(!showLocationModal);
                      }}
                    >
                      <DefaultInput title="Select Location" />
                    </InputCard>
                  </div>
                  <div className="mx-6">
                    <InputCard title="Remainder" className="w-52 lg:w-60 mb-3">
                      <Flatpickr
                        placeholder="No Remaind"
                        className="w-full h-full outline-none text-sm"
                        options={{
                          dateFormat: "d-m-Y H:i",
                          minDate: "today",
                          enableTime: true,
                        }}
                        {...Field}
                        onChange={(date: any) => {
                          setFieldValue("display_date", date[0]);
                        }}
                      />
                    </InputCard>

                    <InputCard
                      title="Event"
                      className="w-52 lg:w-60 my-1"
                      onClick={() => {
                        setShowEventModal(!showEventModal);
                      }}
                    >
                      <DefaultInput title="Select Event" />
                    </InputCard>
                  </div>
                  <InputCard title="Photo" className="h-[145px] w-52 lg:w-60">
                    <PhotoUploadCard />
                  </InputCard>
                </div>
                <div className="flex items-start my-3">
                  <div className="flex items-center h-5">
                    <input
                      name="report_agree"
                      id="agree"
                      type="checkbox"
                      className="form-checkbox w-4 h-4 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-heading checked:hover:bg-heading checked:focus:bg-heading"
                    />
                  </div>

                  <div className="ml-3 ">
                    <label
                      htmlFor="agree"
                      className=" text-sm font-medium text-gray-800"
                    >
                      Exclude from report
                    </label>
                    <div className=" text-xs font-normal text-secondryText">
                      Don't include this transaction in reports such as
                      Overview.
                    </div>
                  </div>
                </div>
              </div>

              <div className="float-right px-5 py-3">
                <Button
                  type="button"
                  variant="smoke"
                  className="mr-4"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  SAVE
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        ;
      </div>

      {/* WALLET MODAL */}

      <Modal
        open={showWalletModal}
        onClose={() => setshowWalletModal(!showWalletModal)}
        useOutsideClick={false}
      >
        <WalletSelectionModal
          onSelectedData={onSelectedData}
          onClick={() => {
            setshowWalletModal(!showWalletModal);
          }}
        />
      </Modal>

      {/* CATEGORY MODAL*/}

      <Modal
        open={showCategoryModal}
        onClose={() => setShowCategoryModal(!showCategoryModal)}
        useOutsideClick={false}
      >
        <CategorySelectionModal
          data={data}
          onSelectedCategory={onSelectedCategory}
          onClick={(values: any) => {
            setShowCategoryModal(!showCategoryModal);
          }}
        />
      </Modal>

      {/* DATE PICKER MODAL*/}

      <Modal
        open={showDate}
        onClose={() => setShowDate(!showDate)}
        useOutsideClick={false}
      >
        <Flatpickr
          options={{
            inline: true,
          }}
        />
      </Modal>

      {/* CONTACT MODAL*/}

      <Modal
        open={showContactModal}
        onClose={() => setShowContactModal(!showContactModal)}
        useOutsideClick={false}
      >
        <ContactSelectionModal
          onClick={() => {
            setShowContactModal(!showContactModal);
          }}
        />
      </Modal>

      {/* LOCATION MODAL*/}

      <Modal
        open={showLocationModal}
        onClose={() => setShowLocationModal(!showLocationModal)}
        useOutsideClick={false}
      >
        <LocationModal
          onClick={() => {
            setShowLocationModal(!showLocationModal);
          }}
        />
      </Modal>

      {/* Event MODAL*/}

      <Modal
        open={showEventModal}
        onClose={() => setShowEventModal(!showEventModal)}
        useOutsideClick={false}
      >
        <EventSelectionModal
          onClick={() => {
            setShowEventModal(!showEventModal);
          }}
        />
      </Modal>
    </>
  );
}

export default EditTransaction;
