import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import http from "../utils/http";

export interface LoginInputType {
  username:String;
    password:String;
}
async function login(input: LoginInputType) {
  return http.post(API_ENDPOINTS.LOGIN,input);
}

export const useLoginMutation = () => {
  return useMutation((input: LoginInputType) => login(input), {
    onSuccess:({data})=>{

          localStorage.setItem("ACCESS_TOKEN",data.token)

    }
  });
};
