import CategoryTab from "components/ui/CategryTab";
import SearchBar from "components/ui/SearchBar";
import { useApplication } from "contexts/application/application.context";
import { useCategoryQuery } from "framework/basic-rest/category/get-categorys";
import { GrClose } from "react-icons/gr";

type Props = {
  onClick?: any;
  data?:any
  onSelectedCategory?: Function;
};

export default function CategorySelectionModal({
  onClick,
  data,
  onSelectedCategory,
}: Props) {
  const { wallet, initialWallet } = useApplication();

  
  return (
    <div className="w-96 h-500px">
      <div className="h-5/6">
        <div className=" sticky top-0 bg-white">
          <div className="h-14 flex items-center border-b border-gray-300 p-6 ">
            <GrClose
              className="text-lg mr-2"
              onClick={() => {
                if (typeof onClick == "function") {
                  onClick();
                }
              }}
            />
            <h1 className="text-lg font-medium text-heading px-5">
              Select category
            </h1>
          </div>
          <div className="my-3 px-3">
            <SearchBar />
          </div>
        </div>
       <div className="h-[90%]">
       <CategoryTab
          onSelectedCategory={(values: any) => {
            if (typeof onSelectedCategory == "function") {
              onSelectedCategory(values);
            }
          }}
          onClick={() => {
            onClick();
          }}
          category={data}
        />
       </div>
      </div>
    </div>
  );
}
